import React, {
  ForwardedRef,
  useImperativeHandle,
  useState,
  ForwardRefRenderFunction,
  forwardRef,
} from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { StoreSelectorItemProps, StoreSelectorItemHandler } from '../types';
import { View, Platform } from 'react-native';
import { TouchableOpacity } from '@gorhom/bottom-sheet';
import { Text } from 'assets/components/text';
import BaseRadioButton from 'assets/components/radio-button-group/BaseRadioButton';
import { InfoIcon } from 'assets/icons';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { PermissionStatus } from 'expo-location';
import { Distance } from '../../../types';
import {
  PharmacyLocationDto,
  TimeRangeDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { Icon } from 'assets/components/icon';
import { updateLocationDetails } from '../../../screens/register/register-actions';
import { StoreSelectorInformationModal } from '../StoreSelectorInformationModal';
import { StoreSelectorInformationBottomSheet } from '../StoreSelectorInformationBottomSheet';
import { getText } from 'assets/localization/localization';

export const StoreSelectorItem: ForwardRefRenderFunction<
  StoreSelectorItemHandler,
  StoreSelectorItemProps
> = (
  {
    item = {} as Distance<PharmacyLocationDto>,
    selected = false,
    disabled = false,
    onPress = (item: Distance<PharmacyLocationDto>) => {},
    onInfoButtonPress,
    status,
    showInfoModal,
    ...rest
  }: StoreSelectorItemProps,
  ref: ForwardedRef<StoreSelectorItemHandler>,
) => {
  const theme = useTheme();
  const styles = useStyles();
  const day = new Date().getDay();

  const [showModal, setShowModal] = useState(false);
  const webRef = React.useRef<StoreSelectorItemHandler>(null);
  const nativeRef = React.useRef<StoreSelectorItemHandler>(null);

  const handleInfoButtonOnPress = async () => {
    onInfoButtonPress?.(item);
    if (item.id) await updateLocationDetails(item.id);
    handleOnChangePress();
  };

  const handleShow = () => {
    if (Platform.OS === 'web') webRef.current?.show();
    else nativeRef.current?.show();
  };

  const handleHide = () => {
    if (Platform.OS === 'web') webRef.current?.hide();
    else nativeRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const handleOnChangePress = () => {
    handleShow();
  };

  const currentDayOpenStoreHours = (hoursAsStr: TimeRangeDto[]) => {
    const todayOpenHours = hoursAsStr.find((openHour) => {
      return openHour.day === day;
    });
    return `${todayOpenHours?.start_hour}:00-  ${todayOpenHours?.end_hour}:00`.trim();
  };

  const getDayAsStr = (hours: TimeRangeDto) => {
    switch (hours.day) {
      case 1:
        return getText('monday');
      case 2:
        return getText('tuesday');
      case 3:
        return getText('wednesday');
      case 4:
        return getText('thursday');
      case 5:
        return getText('friday');
      case 6:
        return getText('saturday');
      case 7:
        return getText('sunday');
      default:
        return '';
    }
  };

  return (
    <TouchableOpacity style={styles.container} onPress={() => onPress(item)}>
      <View style={styles.radioButtonContainer}>
        <BaseRadioButton selected={selected} disabled={disabled} />
      </View>

      <View style={{ flex: 1 }}>
        <View style={styles.titleContainer}>
          <View style={{ flex: 1 }}>
            <Text style={styles.title} ellipsizeMode="tail" numberOfLines={1}>
              {item?.name}
            </Text>
          </View>

          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {status === PermissionStatus.GRANTED && item.distance && (
              <Text style={styles.secondTitle}>
                {`${item.distance?.toFixed(2)} ${item.distanceUnit}`}
              </Text>
            )}

            {status === PermissionStatus.UNDETERMINED && (
              <LoadingIndicator size={14} />
            )}

            <TouchableOpacity
              style={styles.iconContainer}
              onPress={handleInfoButtonOnPress}
            >
              <Icon icon={InfoIcon} color={theme.palette.gray[500]} size={14} />
            </TouchableOpacity>
            {Platform.OS === 'web' ? (
              <StoreSelectorInformationModal
                {...rest}
                ref={webRef}
                item={item}
                selected={false}
                onInfoButtonPress={handleInfoButtonOnPress}
                showInfoModal={showModal}
                currentDayOpenHours={currentDayOpenStoreHours}
                daysAsStr={getDayAsStr}
              ></StoreSelectorInformationModal>
            ) : (
              <StoreSelectorInformationBottomSheet
                item={item}
                selected={false}
                onInfoButtonPress={handleInfoButtonOnPress}
                showInfoModal={showModal}
                ref={nativeRef}
                daysAsStr={getDayAsStr}
              />
            )}
          </View>
        </View>
        {item.address && (
          <>
            <Text style={styles.secondTitle}>{item?.address?.address1}</Text>
            <Text style={styles.secondTitle}>
              {`${item?.address.city}, ${item.address.state} ${item.address.postal_code}`}
            </Text>
          </>
        )}
      </View>
    </TouchableOpacity>
  );
};

export const StoreSelectorItemComponent = forwardRef<
  StoreSelectorItemHandler,
  StoreSelectorItemProps
>(StoreSelectorItem);

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(1),
    width: '100%',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
  },
  radioButtonContainer: {
    marginRight: theme.getSpacing(2),
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[900],
    marginRight: theme.getSpacing(1),
  },
  secondTitle: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  iconContainer: { marginLeft: theme.getSpacing(1), alignSelf: 'center' },
}));
