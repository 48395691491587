import { api } from '../../../api';
import { getText } from 'assets/localization/localization';
import { useUserState } from '../../../store/user-store';
import {
  usePatientIntakeState,
  usePatientUnderCareState,
} from './patient-store';
import patientService from '../../../api/patient-service';
import {
  CreateRecordUnderCareRequestDto,
  Gender,
  PatientUnderCareRelationship,
  RecordUnderCareDto,
  RecordUnderCareRequestDto,
  PatientRecordDto,
  UpdateRecordUnderCareRequestDto,
} from '@digitalpharmacist/patient-service-client-axios';
import moment from 'moment';
import FileStorageService from '../../../api/file-storage-service';
import { PharmacyCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { Alert } from 'react-native';
import { uuid } from 'assets/utils/uuid';

export const updatePatientIntake = (
  partial:
    | PatientInfoForm
    | MedicalInfoForm
    | PatientPreferencesForm
    | PatientFeedback
    | PatientInsuranceForm,
) => {
  usePatientIntakeState.setState((state) => {
    return { data: { ...state, ...partial } };
  });
};

export const updatePatientUnderCareForm = (partial: PatientUnderCareForm) => {
  usePatientUnderCareState.setState((state) => {
    return { data: { ...state, ...partial } };
  });
};

export const submitPatientUnderCareRequest = async (
  values: PatientUnderCareForm,
): Promise<void> => {
  const userId = useUserState.getState().user?.id;
  usePatientUnderCareState.setState({ error: undefined, status: 'loading' });
  try {
    const formattedDOB = moment(values.date_of_birth, 'MM-DD-YYYY').format(
      'YYYY-MM-DD',
    );
    const userId = useUserState.getState().user?.id;

    if (!userId) throw new Error("Patient record id doesn't exist");
    const createRecordUnderCareRequestDto: CreateRecordUnderCareRequestDto = {
      ...values,
      date_of_birth: formattedDOB,
    };
    if (!userId) {
      throw new Error('');
    }
    const response = patientService.createRecordUnderCareRequest(
      userId,
      createRecordUnderCareRequestDto,
    );
    usePatientUnderCareState.setState({ status: 'success' });
  } catch (e) {
    usePatientUnderCareState.setState({
      error: { message: getText('unable-to-submit-people-under-care-request') }, //TODO sync with Aurea and Curtis for error messages
      status: 'error',
    });
  }
};

export const refreshRecordUnderCareRequestsState = async (): Promise<void> => {
  const patientRecordId = useUserState.getState().user?.patientRecordId;
  if (!patientRecordId) {
    throw new Error('Patient id not found');
  }
  const recordUnderCareRequests: RecordUnderCareRequestDto[] =
    await patientService.findPendingRequestsByRecordUnderCare(patientRecordId);
  const caregivers: RecordUnderCareDto[] =
    await patientService.findRecordUnderCareByRecordUnderCare(patientRecordId);
  usePatientUnderCareState.setState({
    recordUnderCareRequests: recordUnderCareRequests,
    caregivers: caregivers,
  });
};

export const respondToRequestUnderCare = async (
  requestId: string,
  updateRecordUnderCareRequestDto: UpdateRecordUnderCareRequestDto,
): Promise<void> => {
  const userId = useUserState.getState().user?.id;
  if (!userId) throw new Error('User id not found');
  await patientService.patchRequestsRecordUnderCare(
    userId,
    requestId,
    updateRecordUnderCareRequestDto,
  );
};

export const refreshCaregiverRequestsState = async (): Promise<void> => {
  const userId = useUserState.getState().user?.id;
  if (!userId) throw new Error("User id doesn't exist");
  const requestsByCaregiver: RecordUnderCareRequestDto[] =
    await patientService.findPendingRequestsByCaregiver(userId);
  const recordsUnderCare: RecordUnderCareDto[] =
    await patientService.findRecordUnderCareByPatientUser(userId);
  usePatientUnderCareState.setState({
    caregiverRequests: requestsByCaregiver,
    recordsUnderCare: recordsUnderCare,
  });
};

export async function uploadFiles(
  pharmacyId: string,
  frontImageFileName: string | undefined,
  backImageFileName: string | undefined,
  frontImage: Blob,
  backImage: Blob,
): Promise<{
  frontImageFileName: string;
  backImageFileName: string;
}> {
  const patientId = useUserState.getState().user?.patientRecordId;

  if (!patientId) {
    throw new Error('Patient Id missing');
  }
  usePatientIntakeState.setState({
    status: 'loading',
  });

  function getFilename(patientId: string, side: string) {
    return `${uuid()}_${side}_${patientId}.png`;
  }

  // generate missing filenames
  frontImageFileName = frontImageFileName ?? getFilename(patientId, 'front');
  backImageFileName = backImageFileName ?? getFilename(patientId, 'back');

  // handle image (generic)
  async function uploadFile(filename: string, file: Blob): Promise<void> {
    const responseWriteUrl = await FileStorageService.writeUrl(
      PharmacyCategory.InsuranceCard,
      filename,
      pharmacyId,
    );
    try {
      await FileStorageService.uploadFile(file, responseWriteUrl.url);
      usePatientIntakeState.setState({
        status: 'success',
      });
    } catch (error) {
      usePatientIntakeState.setState({
        error: { message: getText('insurance-card-upload-error') },
        status: 'error',
      });
    }
  }

  await uploadFile(frontImageFileName, frontImage);
  await uploadFile(backImageFileName, backImage);

  return {
    frontImageFileName,
    backImageFileName,
  };
}

export interface PatientInfoForm {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  gender?: string;
  preferredPharmacyLocationId: string;
}

export interface MedicalInfoForm {
  allergies: string;
  medicalConditions: string;
  otcMedications: string;
}

export interface PatientPreferencesForm {
  appNotifications: boolean;
  automatedVoiceCall: boolean;
  email: boolean;
  textMessage: boolean;
}

export interface PatientInsuranceForm {
  frontPhoto: Blob;
  backPhoto: Blob;
}

export interface PatientFeedback {
  target: 'app' | 'location';
  feedback: string;
  allowContact: boolean;
  pharmacyId: string;
  locationId?: string;
  patientId: string;
  osVersion: string;
  appVersion: string;
  appName: string;
  device: string;
}

export interface PatientStore {
  target: 'app' | 'location';
  feedback: string;
  allowContact: boolean;
  pharmacyId: string;
  locationId?: string;
  patientId: string;
  osVersion: string;
  appVersion: string;
  appName: string;
  device: string;
}

export interface PatientUnderCareForm {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  relationship: PatientUnderCareRelationship;
  email?: string;
  requester_id: string;
  guardianship_approved: boolean;
  gender?: Gender;
  allergies?: ResourceItem[];
  medical_conditions?: ResourceItem[];
  prefers_easy_open_bottle_caps?: boolean;
  prefers_med_sync?: string;
  patient_record_id?: string;
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  state?: string;
  postal_code?: string;
  prefers_easy_open_bottle_caps_str?: string;
}

export interface ResourceItem {
  value: string;
  text: string;
}
