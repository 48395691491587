import patientService from '../../api/patient-service';
import { useUserState } from '../../store/user-store';
import { SubjectOptionsEnum } from './MessageProps';
import { useMessagesState } from './messages-store';
import { IMessage, User } from 'react-native-gifted-chat';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { EmittedMessage, ConversationDtoExtended, Order } from './types';
import {
  PatientUserDto,
  PharmacyUserDto,
} from '@digitalpharmacist/users-service-client-axios';
import UnifiedCommsService from '../../api/unified-comms-service';
import { compare } from '../../../../pharmacy/modules/screens/messages/utils';

export const setInitialSubject = (
  initialSubject: SubjectOptionsEnum | undefined,
) => {
  useMessagesState.setState({ initialSubject });
};

export const setShowMessageModal = (showModal: boolean) => {
  useMessagesState.setState({ showModal });
};

export const setSelectedConversation = (selectedConversation: string) => {
  useMessagesState.setState({ selectedConversation: selectedConversation });
};

export const removeSelectedConversation = () => {
  useMessagesState.setState({ selectedConversation: null });
};

export const setViewedConversations = (viewedConversations: string[]) => {
  useMessagesState.setState({ viewedConversations: viewedConversations });
};

export const setMessagesGroups = (
  messagesGroups: ConversationDtoExtended[],
) => {
  useMessagesState.setState({ messagesGroups: messagesGroups });
};

export const setSelectedMessages = (selectedMessages: IMessage[]) => {
  useMessagesState.setState({ selectedMessages: selectedMessages });
};

export const removeSelectedMessages = () => {
  useMessagesState.setState({ selectedMessages: [] });
};

export const setRawConversations = (
  rawConversations: DirectMessagePatientDto[],
) => {
  useMessagesState.setState({ rawConversations: rawConversations });
};

export const openNewChatModal = () => {
  useMessagesState.setState({ isOpenNewChatModal: true });
};

export const closeNewChatModal = () => {
  useMessagesState.setState({ isOpenNewChatModal: false });
};

export const setIncomeMessage = (
  message: EmittedMessage,
  author: PharmacyUserDto | PatientUserDto | undefined,
) => {
  useMessagesState.setState((state) => {
    const isMessageNew = !state.selectedMessages.find(
      (item) => message.id === item._id,
    );

    let newSelectedMessages: IMessage[] = [];
    if (isMessageNew && state.selectedConversation == message.conversation_id) {
      const user: User = { _id: message.author_id };
      if (author) {
        user.name = author.firstName + ' ' + author.lastName;
      }
      const newMessage: IMessage = {
        _id: message.id,
        createdAt: new Date(message.created_at),
        text: message.content,
        user,
      };
      newSelectedMessages = [newMessage, ...state.selectedMessages];
    }

    const newState = {
      ...state,
    };

    if (newSelectedMessages.length > 0) {
      newState.selectedMessages = newSelectedMessages;
    }

    const conversationIndex = state.rawConversations.findIndex((patient) => {
      return message.conversation_id == patient.conversation_id;
    });
    let newConversations: DirectMessagePatientDto[] = [];
    if (conversationIndex > -1) {
      newConversations = [...state.rawConversations];

      newConversations[conversationIndex] = {
        ...newConversations[conversationIndex],
        patient_viewed_all_messages: !isMessageNew,
        most_recent_qualifying_message: message.content,
        most_recent_qualifying_message_date: message.created_at.toString(),
      };
    }

    if (newConversations.length > 0) {
      newState.rawConversations = newConversations;
    }

    return newState;
  });
};

export const setNewConversation = (conversation: ConversationDtoExtended) => {
  useMessagesState.setState((state) => {
    const newState = { ...state };
    const newMessagesGroups = [{ ...conversation }, ...state.messagesGroups];
    newState.messagesGroups = newMessagesGroups;
    return newState;
  });
};

export const setCounts = (count: { unread: number }) => {
  useMessagesState.setState((state) => {
    const newState = { ...state };
    if (count.unread > 0) {
      return { ...newState, count };
    }
    return { ...state, count: {} };
  });
};

export const buildMessageList = async (): Promise<void> => {
  const user = useUserState.getState().user;
  if (user?.patientRecordId) {
    const patientRecord = await patientService.findPatientRecord(
      user.patientRecordId,
    );

    const locationPatientRecord = patientRecord.location_patient_records.find(
      (record) => record.location_id === user.preferredPharmacyLocationId,
    );

    if (locationPatientRecord) {
      const rawConversationsData =
        await UnifiedCommsService.getAllConversationsByPatientAndLocation(
          locationPatientRecord.location_id,
          locationPatientRecord.id,
        );

      const sortedConversationsData = rawConversationsData.sort(
        (currentConversation, nextConversation) =>
          compare(
            currentConversation,
            nextConversation,
            'most_recent_qualifying_message_date',
            Order.DESC,
            true,
          ),
      );

      setRawConversations(sortedConversationsData);
    } else {
      setRawConversations([]);
    }
  }
};
