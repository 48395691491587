import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { AccountTestIDs } from './AccountTestIDs';
import { useUserState } from '../../store/user-store';
import { makeStyles, useTheme } from 'assets/theme';
import { Avatar } from 'assets/components/avatar';
import { logout } from '../login/login-actions';
import {
  useNavigation,
  NavigationProp,
  ParamListBase,
} from '@react-navigation/native';
import { AccountStackNavigationProp } from './AccountNavigation';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';
import { ListItemLink, ListMenu } from 'assets/components/list-menu';
import { usePatientUnderCareState } from './patient/patient-store';
import { refreshCaregiverRequestsState } from './patient/patient-actions';

export const Account: FunctionComponent<PropsWithChildren<AccountProps>> = (
  props,
) => {
  const styles = useStyles();
  const theme = useTheme();
  const { user } = useUserState();
  const { recordsUnderCare, caregiverRequests } = usePatientUnderCareState();

  const fullName = `${user?.firstName} ${user?.lastName}`.trim();
  const navigation = useNavigation<AccountStackNavigationProp>();
  const navPUC = useNavigation<NavigationProp<ParamListBase>>();

  useEffect(() => {
    refreshCaregiverRequestsState();
  }, []);

  const onPress = async () => {
    navPUC.navigate('people-under-care', {
      screen: 'add-patient-under-care-info',
    });
  };

  const handleFullProfile = () => {
    // TODO: follow on card will fill out other profile screens such as this
  };

  const handleLogout = () => {
    logout();
  };

  const handleInsuranceCard = () => {
    navigation.navigate('patient-insurance', {
      callback: () => {
        navigation.navigate('home');
      },
    });
  };

  const handleMedicalInfoClick = async () => {
    navigation.navigate('medical-info');
  };

  const handlePersonalInfoClick = async () => {
    navigation.navigate('personal-information');
  };

  const handleItemClick = async () => {
    if (caregiverRequests.length > 0 || recordsUnderCare.length > 0) {
      navPUC.navigate('people-under-care', {
        screen: 'patient-under-care-list',
      });
    } else {
      navPUC.navigate('people-under-care', { screen: 'patient-under-care' });
    }
  };

  return (
    <ScreenContainer>
      <View
        style={{
          marginBottom: theme.getSpacing(2),
          marginTop: theme.getSpacing(1),
          alignItems: 'center',
        }}
      >
        <Avatar name={fullName} size={96} border={true} />
      </View>
      <View>
        <ListMenu style={styles.container}>
          <ListItemLink onPress={handleMedicalInfoClick}>
            <Text style={styles.textTitle}>{getText('medical-info')}</Text>
            <Text style={styles.textUnderTitle}>
              {getText('allergies-conditions-caregivers')}
            </Text>
          </ListItemLink>
          <ListItemLink onPress={() => {}}>
            {/*  Full implementation will come with BLUES-1391 */}
            <Text style={styles.textTitle}>{getText('personal-info')}</Text>
            <Text style={styles.textUnderTitle}>{getText('coming-soon')}</Text>
          </ListItemLink>
          <ListItemLink onPress={() => {}}>
            <Text style={styles.textTitle}>{getText('insurance-id-card')}</Text>
            <Text style={styles.textUnderTitle}>{getText('coming-soon')}</Text>
          </ListItemLink>
          <ListItemLink onPress={handlePersonalInfoClick}>
            <Text style={styles.textTitle}>{getText('personal-info')}</Text>
            <Text>{getText('name-phone-email')}</Text>
          </ListItemLink>
          <ListItemLink onPress={handleItemClick}>
            <Text style={styles.textTitle}>
              {getText('people-under-your-care')}
            </Text>
            <View>
              {recordsUnderCare && recordsUnderCare.length > 0 ? (
                <Text style={styles.textUnderTitle}>
                  {recordsUnderCare
                    .slice(0, Math.min(2, recordsUnderCare.length))
                    .map((record) => record.record_under_care.first_name)
                    .join(', ')}
                  {recordsUnderCare.length > 2 &&
                    ` and ${recordsUnderCare.length - 2} ${getText('others')}`}
                </Text>
              ) : (
                <TouchableOpacity onPress={onPress}>
                  <Text style={styles.textUnderTitle}>
                    {getText('add-person')}...
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </ListItemLink>
          <ListItemLink onPress={() => {}}>
            <Text style={styles.textTitle}>{getText('patient-forms')}</Text>
            <Text style={styles.textUnderTitle}>{getText('coming-soon')}</Text>
          </ListItemLink>
          <ListItemLink onPress={() => {}}>
            <Text style={styles.textTitle}>
              {getText('communication-preferences')}
            </Text>
            <Text style={styles.textUnderTitle}>{getText('coming-soon')}</Text>
          </ListItemLink>
        </ListMenu>
      </View>
      {
        //TODO: Add button for feedback widget back when BE is ready
        /*<View style={{ marginBottom: theme.getSpacing(2) }}>
        <Button
          onPress={() => {
            navigation.navigate("feedback");
          }}
          hierarchy="secondary-gray"
          logger={{ id: "profile-give-feedback-button" }}
        >
          {getText("feedback-give-feedback")}
        </Button>
      </View>*/
      }
      <View style={{ marginBottom: theme.getSpacing(2) }}>
        <TouchableOpacity onPress={handleLogout} testID={AccountTestIDs.logout}>
          <Text style={styles.touchableText}>{getText('sign-out')}</Text>
        </TouchableOpacity>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: 24,
    fontWeight: '500',
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 20,
    marginTop: theme.getSpacing(3),
    lineHeight: 30,
  },
  textUnderTitle: {
    ...theme.fonts.regular,
    color: theme.palette.gray[600],
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
  },
  touchableText: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 28,
  },
}));

interface AccountProps {}
