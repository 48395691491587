import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { Button } from 'assets/components/button';
import {
  GOOGLE_WEB_CLIENT_ID,
  GOOGLE_ANDROID_CLIENT_ID,
  GOOGLE_EXPO_CLIENT_ID,
  GOOGLE_IOS_CLIENT_ID,
} from '../../common/constants';
import { getText } from 'assets/localization/localization';
import { GoogleIcon } from 'assets/icons';
import { googleLogin } from '../../screens/login/login-actions';
import { AccountStackNavigationProp } from '../../screens/account/AccountNavigation';
import { useNavigation } from '@react-navigation/native';
import { makeStyles } from 'assets/theme';
import { notImplementedAlert } from 'assets/utils/alert';

WebBrowser.maybeCompleteAuthSession();

export const GoogleSignInButton: React.FunctionComponent<
  GoogleSignInButtonProps
> = ({ text = getText('google-signup'), style = {} }) => {
  const styles = useStyles();
  const [request, response, promptAsync] = Google.useAuthRequest({
    scopes: ['email'],
    webClientId: GOOGLE_WEB_CLIENT_ID,
    expoClientId: GOOGLE_EXPO_CLIENT_ID,
    iosClientId: GOOGLE_IOS_CLIENT_ID,
    androidClientId: GOOGLE_ANDROID_CLIENT_ID,
  });

  const navigation = useNavigation<AccountStackNavigationProp>();

  React.useEffect(() => {
    if (response?.type === 'success') {
      const { authentication } = response;
      (async () => {
        await googleLogin({
          accessToken: authentication?.accessToken ?? '',
        });
        navigation.navigate('home');
      })();
    }
  }, [response]);

  return (
    <Button
      icon={GoogleIcon}
      disabled={!request}
      // TODO: Put back when Google signin is fully implemented
      /*onPress={() => {
          promptAsync();
        }}*/
      onPress={notImplementedAlert}
      hierarchy={'secondary-gray'}
      logger={{ id: 'google-login-button' }}
      style={[styles.button, style]}
    >
      {text}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    borderColor: theme.palette.gray[300],
    paddingTop: theme.getSpacing(0.5),
    paddingBottom: theme.getSpacing(0.5),
  },
}));

interface GoogleSignInButtonProps {
  text?: string;
  style?: StyleProp<ViewStyle>;
}
