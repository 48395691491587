import create from 'zustand';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

import { ApiError, AsyncStatus } from '../../store/types';

export interface AddPrescriptionState {
  status: AsyncStatus;
  error?: ApiError;
  patientRecord?: PatientRecordDto;
  locationPatientRecordId?: string;
}

export const defaultAddPrescriptionState: AddPrescriptionState = {
  status: 'idle',
  error: undefined,
  patientRecord: undefined,
  locationPatientRecordId: undefined,
};

export const useAddPrescriptionStore = create<AddPrescriptionState>(
  () => defaultAddPrescriptionState,
);
