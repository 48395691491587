import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, Image } from 'react-native';
import { PHARMACY_ID, STATIC_DEV_LUMISTRY } from '../../common/constants';

export const Logo: FunctionComponent<PropsWithChildren<LogoProps>> = () => {
  const uri = STATIC_DEV_LUMISTRY + PHARMACY_ID + '/images/logo_on_dark.png';
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Image
        source={{
          uri: uri,
        }}
        style={styles.logo}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    width: 241,
    height: 105,
    resizeMode: 'contain',
  },
}));

interface LogoProps {}
