import { ScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';
import { ArrowLeftCircleIcon, PillsIcon, ShoppingBagIcon } from 'assets/icons';
import { PrescriptionCard } from '../../components/prescription-card';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { Divider } from 'react-native-paper';
import { Button } from 'assets/components/button';
import { DropdownSelect } from 'assets/components/dropdown-select';
import { useForm } from 'assets/form';
import { useRefillMedicationsStore } from '../refill/refill-store';
import refillService from '../refill/refill-service';
import { OrderMedicationsHandler } from './order-medications-alert/OrderMedicationsAlertProps';
import patientService from '../../api/patient-service';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { UnableToSubmitHandler } from '../refill/unable-to-submit/UnableToSubmitProps';
import { UnableToSubmit } from '../refill/unable-to-submit/UnableToSubmit';
import { ampli } from '../../ampli';
import { useUserState } from '../../store/user-store';
import { EmptyStatePage } from '../../components/empty-state-page/EmptyStatePage';
import { OrderMedicationsAlert } from './order-medications-alert/OrderMedicationAlert';
import { useAddPrescriptionStore } from '../find-prescription-flow/add-prescription-store';
import { StackHeaderProps } from '@react-navigation/stack';
import { notImplementedAlert } from 'assets/utils/alert';

export const Medications: FunctionComponent<
  PropsWithChildren<MedicationsProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { user } = useUserState();
  const {
    isFirstTimeRegister,
    allMedications,
    selectedPatient,
    updateMedicationsData,
  } = useRefillMedicationsStore();
  const [selectedDependents, setSelectedDependents] = useState<
    { id: string; value: string }[]
  >([]);
  const orderAlertRef = React.useRef<OrderMedicationsHandler>(null);
  const unableToSubmitRef = React.useRef<UnableToSubmitHandler>(null);

  const [overTheCounterMeds, setOverTheCounterMeds] = useState([]); // TODO: get the values from API once it's created

  const methods = useForm({
    defaultValues: {
      selectedLocationPatientRecordId: '',
    },
  });

  useEffect(() => {
    (async () => {
      if (
        !updateMedicationsData ||
        !user?.preferredPharmacyLocationId ||
        !user.patientRecordId ||
        !user.id
      )
        return;

      const patientRecord = await patientService.findPatientRecord(
        user.patientRecordId,
      );

      const locationPatientRecordMyself =
        await patientService.findLocationPatientRecordByPR(
          user.preferredPharmacyLocationId,
          patientRecord,
        );

      if (!locationPatientRecordMyself) return;

      useAddPrescriptionStore.setState({
        locationPatientRecordId: locationPatientRecordMyself.id,
      });

      const selectedDependentsResponse = [
        { id: locationPatientRecordMyself.id, value: 'Myself' },
      ];

      const patientsUnderCare =
        await patientService.recordUnderCareFindByPatientUser(user.id);

      if (patientsUnderCare) {
        for (const patientUnderCare of patientsUnderCare) {
          const locationPatientRecordsForPreferredLocation =
            patientUnderCare.location_patient_records.filter(
              (re) => re.location_id === user.preferredPharmacyLocationId,
            );
          selectedDependentsResponse.push({
            id:
              locationPatientRecordsForPreferredLocation.length > 0
                ? locationPatientRecordsForPreferredLocation[0].id
                : patientUnderCare.id,
            value: `${patientUnderCare.record_under_care.first_name} ${patientUnderCare.record_under_care.last_name}`,
          });
        }
      }

      methods.setValue(
        'selectedLocationPatientRecordId',
        locationPatientRecordMyself.id,
      );

      const prescriptionResponse = await refillService.getPatientPrescriptions(
        user.preferredPharmacyLocationId,
        locationPatientRecordMyself.id,
      );

      updateMedicationsData({
        selectedPatientRecord: patientRecord,
        allMedications: prescriptionResponse.map((x) => ({
          ...x,
          checked: false,
        })),
      });
      setSelectedDependents(selectedDependentsResponse);
    })();
  }, [user?.preferredPharmacyLocationId, user?.patientRecordId]);

  useEffect(() => {
    (async () => {
      if (
        !updateMedicationsData ||
        !user?.preferredPharmacyLocationId ||
        !methods.getValues().selectedLocationPatientRecordId
      )
        return;

      const patientResponse = await patientService.findLocationPatientRecord(
        user.preferredPharmacyLocationId,
        methods.getValues().selectedLocationPatientRecordId,
      );

      if (patientResponse) {
        updateMedicationsData({
          selectedPatient: patientResponse,
        });
      }

      const isFirstTimeRegister = !(await refillService.getLastRefill(
        user.preferredPharmacyLocationId,
        methods.getValues().selectedLocationPatientRecordId,
      ));

      updateMedicationsData({
        showRefillMedications: false,
        isFirstTimeRegister: isFirstTimeRegister,
        selectedPatient: patientResponse,
      });
    })();
  }, [
    methods.getValues().selectedLocationPatientRecordId,
    user?.preferredPharmacyLocationId,
  ]);

  const handleOnPressMedication = (
    medication: PrescriptionDto,
    checked: boolean,
  ) => {
    if (!updateMedicationsData) return;

    updateMedicationsData({
      allMedications: allMedications?.map((x) => ({
        ...x,
        checked: x.rx_number === medication.rx_number ? checked : x.checked,
      })),
    });
  };

  const handleShowUnableToSubmit = () => {
    unableToSubmitRef.current?.show();
  };

  const handleUnableToSubmit = async (value: string) => {
    if (value === 'continue') {
      navigation.navigate('refill');
    }
    if (value === 'back') {
      unableToSubmitRef.current?.hide();
    }
  };

  const handleOnPress = async () => {
    if (
      !user?.preferredPharmacyLocationId ||
      !updateMedicationsData ||
      !allMedications
    )
      return;

    const checkedMedications = allMedications.filter((x) => x.checked);

    if (checkedMedications.length === 0) {
      orderAlertRef.current?.show();
    } else {
      if (!selectedPatient?.id) return;

      const failedMedications = await refillService.checkPatientPrescriptions(
        checkedMedications,
        user.preferredPharmacyLocationId,
        selectedPatient.id,
      );

      if (failedMedications.length > 0) {
        updateMedicationsData({
          selectedLocationId: user.preferredPharmacyLocationId,
          unableToSubmitMedications: failedMedications,
        });
        if (!isFirstTimeRegister) {
          return handleShowUnableToSubmit();
        }
      }

      updateMedicationsData({
        selectedLocationId: user.preferredPharmacyLocationId,
        selectedMedications: checkedMedications,
      });

      navigation.navigate('refill');
    }
  };

  const handleAddAnotherMed = async () => {
    if (!user?.preferredPharmacyLocationId) return;

    const isLocationIntegrated = await refillService.isLocationIntegrated(
      user.preferredPharmacyLocationId,
    );

    if (isLocationIntegrated) {
      useAddPrescriptionStore.setState({
        locationPatientRecordId: undefined,
      });
      navigation.navigate('add-prescription', {
        screen: 'automatic-prescription-flow',
      });
      return;
    }

    navigation.navigate('add-prescription', {
      screen: 'manual-prescription-flow',
    });
  };

  const handleAddAnotherOTCMed = async () => {
    navigation.navigate('add-prescription', {
      screen: 'manual-prescription-flow',
    });
  };

  const handleTransferMedicationPress = () => {
    notImplementedAlert();
  };

  ampli.medicationsListView();

  return (
    <ScreenContainer>
      <View style={{ marginVertical: theme.getSpacing(1) }}>
        {selectedDependents.length > 1 && (
          <Form methods={methods}>
            <DropdownSelect
              fieldName="selectedLocationPatientRecordId"
              options={selectedDependents.map((item) => ({
                label: item.value,
                value: item.id,
              }))}
            />
          </Form>
        )}
      </View>
      {allMedications?.length === 0 ? (
        <EmptyStatePage
          title={getText('medications-empty-state-title')}
          description={getText('medications-empty-state-description')}
          buttonText={getText('add-meds')}
          icon={PillsIcon}
          onPress={handleAddAnotherMed}
        />
      ) : (
        <>
          <View style={{ marginTop: theme.getSpacing(1) }}>
            <TouchableOpacity
              style={styles.rowView}
              onPress={handleTransferMedicationPress}
            >
              <Icon
                icon={ArrowLeftCircleIcon}
                color={theme.palette.primary[600]}
              />
              <Text style={styles.link}>{getText('transfer-meds')}</Text>
            </TouchableOpacity>
          </View>

          <View style={{ marginVertical: theme.getSpacing(1) }}>
            <View style={styles.rowSpaceBetween}>
              <Text style={styles.titleText}>{getText('prescription')}</Text>
              <Text style={styles.link} onPress={handleAddAnotherMed}>
                {getText('add-another')}
              </Text>
            </View>

            {allMedications?.map((medication) => (
              <View key={medication.rx_number}>
                <View style={{ marginBottom: theme.getSpacing(1) }}>
                  <Divider />
                </View>
                <PrescriptionCard
                  check={medication.checked}
                  selectable={true}
                  showRxNumber={false}
                  showPatientInfo={false}
                  onPress={handleOnPressMedication}
                  prescription={medication}
                  patient={selectedPatient}
                />
              </View>
            ))}
          </View>
          <View style={{ marginVertical: theme.getSpacing(1) }}>
            <View style={styles.rowSpaceBetween}>
              <Text style={styles.titleText}>
                {getText('over-the-counter')}
              </Text>
              <Text style={styles.link} onPress={handleAddAnotherOTCMed}>
                {getText('add-another')}
              </Text>
            </View>
            <View style={{ marginBottom: theme.getSpacing(1) }}>
              <Divider />
            </View>
            {overTheCounterMeds.map((otc, index) => (
              <View key={index}>
                <PrescriptionCard
                  check={false}
                  selectable={true}
                  showRxNumber={false}
                  showPatientInfo={false}
                  onPress={handleOnPressMedication}
                  prescription={otc}
                  patient={selectedPatient}
                />
              </View>
            ))}
          </View>
          <View style={styles.stickyButton}>
            <Button
              hierarchy="primary"
              testID={'order-button-id'}
              logger={{ id: 'order-button-id' }}
              icon={ShoppingBagIcon}
              onPress={handleOnPress}
            >
              {getText('order')}
            </Button>
          </View>
          {orderAlertRef && <OrderMedicationsAlert ref={orderAlertRef} />}
          <UnableToSubmit
            ref={unableToSubmitRef}
            handleUnableToSubmit={handleUnableToSubmit}
          />
        </>
      )}
    </ScreenContainer>
  );
};

export type MedicationsProps = StackHeaderProps;

export interface MedicationsBottomSheetHandler {
  show: () => void;
  hide: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
  divider: {
    margin: theme.getSpacing(1),
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.getSpacing(2),
    gap: theme.getSpacing(0.5),
    backgroundColor: '#FEFBE8',
    border: '1px solid #BCC8CE',
    borderRadius: 6,
    marginVertical: theme.getSpacing(1),
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(2),
    marginTop: theme.getSpacing(1),
  },
  stickyButton: {
    position: 'absolute',
    width: Platform.OS === 'web' ? 50 : 100,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    right: 30,
    bottom: 30,
    resizeMode: 'contain',
  },
  link: {
    textAlign: 'right',
    color: theme.palette.primary[600],
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
  },
}));
