import {
  PharmacyLocationDto,
  TimeRangeDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import create from 'zustand';
import pharmacyService from '../api/pharmacy-service';
import { PHARMACY_ID, PHARMACY_NAME } from '../common/constants';

export interface AppState {
  pharmacyId: string;
  pharmacyName: string;
  stores: PharmacyLocationDto[];
  getStores: () => Promise<void>;
  locationStoreHours: TimeRangeDto[];
  locationPharmacyHours: TimeRangeDto[];
  locationLanguages: string[];
  locationServices: string[];
  isMobileOrTablet?: boolean;
}

export const useAppStateStore = create<AppState>((set) => ({
  pharmacyId: PHARMACY_ID,
  pharmacyName: PHARMACY_NAME,
  stores: [],
  getStores: async () => {
    const pharmacyLocations = await pharmacyService.findLocationsByPharmacyId();
    set({ stores: pharmacyLocations });
  },
  locationStoreHours: [],
  locationPharmacyHours: [],
  locationLanguages: [],
  locationServices: [],
}));
