import React, { FunctionComponent, PropsWithChildren } from 'react';
import { AuthNavigation, AuthNavigationHeader } from './AuthNavigation';
import { isUserAuthorized, useUserState } from '../store/user-store';
import { AccountStackParamList } from '../screens/account/AccountNavigation';
import {
  RefillNavigation,
  RefillStackParamList,
} from '../screens/refill/RefillNavigation';
import { NavigatorScreenParams } from '@react-navigation/native';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import {
  StackNavigationProp,
  createStackNavigator,
} from '@react-navigation/stack';
import { BottomTabsNavigation } from './BottomTabsNavigation';
import { FindPrescriptionNavigation } from '../screens/find-prescription-flow/FindPrescriptionNavigation';
import { WebTabsNavigation } from './WebTabsNavigation';
import { Platform } from 'react-native';
import { PatientUnderCareNavigation } from '../screens/account/patient/PatientUnderCareNavigation';
import { useAppStateStore } from '../store/app-store';
import { getText } from 'assets/localization/localization';
import { useRefillMedicationsStore } from '../screens/refill/refill-store';
import { ConversationNavigation } from '../screens/messages/ConversationNavigation';

export const RootNavigation: FunctionComponent<
  PropsWithChildren<RootNavigationProps>
> = ({}) => {
  const Stack = createStackNavigator();
  const isAuthorized = useUserState((x) => isUserAuthorized(x.user));

  const { isFirstTimeRegister } = useRefillMedicationsStore();
  const { isMobileOrTablet } = useAppStateStore();

  if (!isAuthorized) {
    return <AuthNavigation />;
  } else {
    return (
      <Stack.Navigator
        screenOptions={{
          header: AuthNavigationHeader,
          headerShown: false,
          title: getText('app'), // fallback title
        }}
      >
        {Platform.OS === 'web' && !isMobileOrTablet ? (
          <Stack.Screen name="app" component={WebTabsNavigation} />
        ) : (
          <Stack.Screen name="app" component={BottomTabsNavigation} />
        )}
        <Stack.Screen
          name="refill"
          component={RefillNavigation}
          options={{ title: getText('refill') }}
        />
        {!isFirstTimeRegister && (
          <Stack.Screen
            name="add-prescription"
            component={FindPrescriptionNavigation}
            options={{ title: getText('add-prescription') }}
          />
        )}
        <Stack.Screen name="conversation" component={ConversationNavigation} />
        <Stack.Screen
          name="people-under-care"
          component={PatientUnderCareNavigation}
        />
      </Stack.Navigator>
    );
  }
};

interface RootNavigationProps {}

export type RootNavigationParamList = {
  home: undefined;
  medications: NavigatorScreenParams<RefillStackParamList>;
  messages: undefined;
  account: NavigatorScreenParams<AccountStackParamList>;
  refill: undefined;
  forms: {
    form_id: string;
    location_id: string;
  };
  conversation: undefined;
  'add-prescription': undefined;
  'people-under-care': undefined;
  'conversation-box': undefined;
  appointments: undefined;
  appointment: {
    appointment_id: string;
    location_id: string;
  };
};

export type DashboardScreenRouteProp = BottomTabNavigationProp<
  RootNavigationParamList,
  'home'
>;
export type MedicationsScreenRouteProp = BottomTabNavigationProp<
  RootNavigationParamList,
  'medications'
>;
export type MessagesScreenRouteProp = BottomTabNavigationProp<
  RootNavigationParamList,
  'messages'
>;
export type ProfileScreenRouteProp = BottomTabNavigationProp<
  RootNavigationParamList,
  'account'
>;
export type FillInFormScreenRouteProp = BottomTabNavigationProp<
  RootNavigationParamList,
  'forms'
>;
export type AppointmentsScreenRouteProp = BottomTabNavigationProp<
  RootNavigationParamList,
  'appointments'
>;
export type AppointmentDetailsScreenRouteProp = BottomTabNavigationProp<
  RootNavigationParamList,
  'appointment'
>;
