import React, { FunctionComponent, useState } from 'react';
import { View, Platform } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text/Text';
import { getLocale, getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon/Icon';
import { ArrowRightIcon, CalendarIcon, InfoIcon } from 'assets/icons';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { IconButton } from 'assets/components/icon-button';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { Modal } from 'assets/components/modal';
import {
  OrderDto,
  OrderItemDto,
} from '@digitalpharmacist/order-service-client-axios';
import moment from 'moment';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { Divider } from 'react-native-paper';
import { Button } from 'assets/components/button';
import refillService from '../../screens/refill/refill-service';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { MedicationDetails } from '../medication-details/MedicationDetails';

export const PastOrdersPrescriptionCard: FunctionComponent<
  PrescriptionCardProps
> = ({ order, patient, onPress, handleOpenOrderDetails }) => {
  const theme = useTheme();
  const styles = useStyles();

  const sheetRef = React.useRef<BottomSheetModal>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPrescription, setSelectedPrescription] =
    useState<PrescriptionDto>();

  const handleOpenMedDetails = async (refillItem: OrderItemDto) => {
    const prescription = await refillService.getPatientPrescription(
      refillItem.item_name,
      order.location_id,
      order.patient_id,
    );

    setSelectedPrescription(prescription);
    Platform.OS === 'web' ? setModalIsOpen(true) : sheetRef.current?.present();
  };

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
  };

  const createdOrderDate = moment(order.updated_at);
  const createdOrderDateText = [
    createdOrderDate.toDate().toLocaleString(getLocale(), {
      month: 'short',
    }),
    createdOrderDate.toDate().getDate(),
  ].join(' ');

  const dateCreated = moment(order.updated_at, 'YYYY-MM-DD').format(
    'MM/DD/YYYY',
  );
  const createdOrderNumberOfDays = [createdOrderDate.fromNow()].join('');

  moment.locale('en', {
    relativeTime: {
      future: `${getText('in')} %s`,
      past: `%s ${getText('ago')}`,
      s: '1s',
      ss: '%ss',
      m: `1${getText('month-short')}`,
      mm: '%dm',
      h: `1${getText('hour-short')}`,
      hh: '%dh',
      d: `1${getText('day-short')}`,
      dd: '%dd',
    },
  });

  const dateOfBirth = moment(patient.date_of_birth, 'YYYY-MM-DD').format(
    'M/DD/YYYY',
  );

  return (
    <View style={styles.container}>
      <View style={styles.rowSpaceBetween}>
        <View style={styles.rowView}>
          <Icon icon={CalendarIcon} color={theme.palette.gray[500]} size={14} />
          <Text style={styles.dateText}>
            {' '}
            {getText('ordered')} {dateCreated}
          </Text>
        </View>
        <View style={styles.rowView}>
          <IconButton
            icon={ArrowRightIcon}
            onPress={handleOpenOrderDetails}
            color={theme.palette.gray[500]}
            logger={{ id: 'icon-button-id' }}
          />
        </View>
      </View>
      <View>
        <Divider />
      </View>
      <View style={styles.rowView}>
        <Text style={styles.textTitle}>{getText('order')} #: </Text>
        <Text style={styles.textDetails}>
          {order.order_id.length > 10
            ? order.order_id.substring(order.order_id.length - 10)
            : order.order_id}
        </Text>
      </View>
      <View style={styles.rowView}>
        <Text style={styles.textTitle}>
          {patient.first_name} {patient.last_name}
        </Text>
        <Text style={styles.textDetails}> ({dateOfBirth})</Text>
      </View>
      {order.items &&
        order.items.map((item, index) => (
          <View key={index}>
            <View style={styles.rowView}>
              <Text style={styles.medsText}>{item.item_description}</Text>
              <IconButton
                icon={InfoIcon}
                color={theme.palette.gray[500]}
                onPress={() => handleOpenMedDetails(item)}
                logger={{ id: 'med-details-icon-button' }}
                size={20}
                style={{ margin: 4 }}
              />
            </View>
            {item.is_over_the_counter && (
              <Text style={styles.overTheCounterText}>
                {getText('over-the-counter')}
              </Text>
            )}
          </View>
        ))}
      <View style={styles.buttonView}>
        <Button
          onPress={onPress}
          size="small"
          hierarchy="primary"
          testID={'order-button-id'}
          logger={{ id: 'order-button-id' }}
          style={styles.button}
        >
          {getText('order-refill')}
        </Button>
      </View>

      {Platform.OS === 'web' ? (
        <Modal
          title={getText('personal-info')}
          show={modalIsOpen}
          okButtonProps={{
            onPress: () => setModalIsOpen(false),
            logger: { id: 'med-details-ok-button-modal' },
          }}
          dismissButtonProps={{
            onPress: () => setModalIsOpen(false),
            logger: { id: 'med-details-dismiss-button-modal' },
          }}
          isScrollable={true}
          height={800}
        >
          {patient && selectedPrescription && (
            <MedicationDetails
              patient={patient}
              prescription={selectedPrescription}
            />
          )}
        </Modal>
      ) : (
        patient &&
        selectedPrescription && (
          <BottomSheet
            title={getText('personal-info')}
            bottomSheetRef={sheetRef}
            height={'80%'}
            onDismiss={handleBottomSheetDismiss}
            children={
              <MedicationDetails
                patient={patient}
                prescription={selectedPrescription}
              />
            }
          />
        )
      )}
    </View>
  );
};

export interface PrescriptionCardProps {
  order: OrderDto;
  patient: LocationPatientRecordDto;
  onPress: () => void;
  handleOpenOrderDetails: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.getSpacing(1),
    backgroundColor: theme.palette.white,
    shadowColor: 'rgba(16, 24, 40, 0.1)',
    shadowOffset: {
      width: 1,
      height: 4,
    },
    shadowOpacity: 0.58,
    shadowRadius: 1.0,
    marginBottom: theme.getSpacing(1),
  },
  textTitle: {
    ...theme.fonts.medium,
    marginTop: theme.getSpacing(1),
    fontWeight: '700',
    fontSize: 17,
    height: 22,
    color: theme.palette.gray[900],
  },
  textDetails: {
    marginTop: theme.getSpacing(1),
    fontWeight: '400',
    fontSize: 17,
    height: 22,
    color: theme.palette.gray[700],
  },
  medsText: {
    fontWeight: '400',
    fontSize: 17,
    height: 22,
    color: theme.palette.gray[900],
  },
  overTheCounterText: {
    fontWeight: '300',
    fontSize: 15,
    height: 20,
    color: theme.palette.gray[900],
  },
  dateText: {
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    height: 17,
    color: theme.palette.gray[500],
  },
  buttonView: {
    height: 50,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  button: {
    borderRadius: 50,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
