import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { View } from 'react-native';
import { Modal } from 'assets/components/modal';
import { PickupTestID } from './PickupTestID';
import { PickupHandler, PickupProps } from './PickupProps';
import { getText } from 'assets/localization/localization';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import { Text } from 'assets/components/text/Text';
import { makeStyles } from 'assets/theme';
const options = [
  {
    text: getText('yes'),
    value: 'yes',
  },
  {
    text: getText('no'),
    value: 'no',
  },
];

export const PickupModalComponent = forwardRef<PickupHandler, PickupProps>(
  (
    {
      allowPickupToday,
      allowPickupUrgent,
      onChange = (value: string) => {},
      handlePickupSubmit = (value: object) => {},
    }: PickupProps,
    ref: ForwardedRef<PickupHandler>,
  ) => {
    const styles = useStyles();

    const methods = useForm({
      defaultValues: {
        pickupPrescriptionToday: '',
        pickupPrescriptionUrgently: '',
      },
    });

    const [showError, setShowError] = useState(false);
    const [showPickupToday, setShowPickupToday] = useState<boolean>(false);
    const [showPickupUrgent, setShowPickupUrgent] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      setShowPickupToday(allowPickupToday);
      if (!allowPickupToday) setShowPickupUrgent(allowPickupUrgent);
    }, []);

    const handleShowModal = () => {
      setShowModal(true);
    };

    const handleDismissModal = () => {
      setShowModal(false);
    };

    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleDismissModal,
    }));

    const handleOnPress = () => {
      if (showPickupToday) {
        if (methods.getValues().pickupPrescriptionToday === '') {
          setShowError(true);
          return;
        }
        setShowError(false);
        if (
          methods.getValues().pickupPrescriptionToday === 'yes' &&
          allowPickupUrgent
        ) {
          setShowPickupUrgent(true);
          setShowPickupToday(false);
          return;
        }
      }
      if (showPickupUrgent) {
        if (methods.getValues().pickupPrescriptionUrgently === '') {
          setShowError(true);
          return;
        }
        setShowError(false);
        setShowPickupUrgent(false);
      }
      handlePickupSubmit(methods.getValues());
      handleDismissModal();
      setShowPickupToday(allowPickupToday);
      if (!allowPickupToday) {
        setShowPickupUrgent(allowPickupUrgent);
      }
    };

    return (
      <Modal
        size="sm"
        title="Pickup"
        show={showModal}
        cancelButtonProps={{
          onPress: handleDismissModal,
          testID: PickupTestID.cancelModalButton,
          logger: { id: PickupTestID.cancelModalButton },
        }}
        okButtonProps={{
          onPress: handleOnPress,
          testID: PickupTestID.okModalButton,
          logger: { id: PickupTestID.okModalButton },
        }}
      >
        <View>
          <Form methods={methods}>
            {showPickupToday && (
              <View>
                <Form.Row>
                  <Form.Column>
                    <RadioButtonGroupField
                      label={getText('will-pickup-prescription-today')}
                      rules={{
                        required: getText('field-required'),
                      }}
                      name={'pickupPrescriptionToday'}
                      values={options}
                    />
                  </Form.Column>
                </Form.Row>
              </View>
            )}
            {showPickupUrgent && (
              <View>
                <Form.Row>
                  <Form.Column>
                    <RadioButtonGroupField
                      label={getText('will-pickup-prescription-urgently')}
                      rules={{
                        required: getText('field-required'),
                      }}
                      name={'pickupPrescriptionUrgently'}
                      values={options}
                    />
                  </Form.Column>
                </Form.Row>
              </View>
            )}
            {showError && (
              <Text style={styles.error}>{getText('select-an-option')}</Text>
            )}
          </Form>
        </View>
      </Modal>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  error: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 24,
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
    color: theme.palette.error[700],
  },
}));
