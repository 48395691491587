import React, { FunctionComponent } from 'react';
import { NavigationTabBar } from '../../components/navigation-tab-bar/NavigationTabBar';
import { Medications } from './Medications';
import { LandingHeader } from '../../components/landing-header';
import { useAppStateStore } from '../../store/app-store';
import { getText } from 'assets/localization/localization';
import { OrderNavigation } from './medication-orders/OrderNavigation';
import { StackNavigationProp } from '@react-navigation/stack';
import { Platform } from 'react-native';

export const MedicationsNavigation: FunctionComponent = () => {
  const { pharmacyName } = useAppStateStore();
  return (
    <>
      {Platform.OS === 'web' ? (
        <></>
      ) : (
        <LandingHeader pharmacyName={pharmacyName} greetings="Medications" />
      )}
      <NavigationTabBar
        screens={[
          {
            name: 'medications',
            component: Medications,
            testID: 'meds-tab-test-id',
            title: getText('meds'),
          },
          {
            title: getText('orders'),
            component: OrderNavigation,
            testID: 'orders-tab-test-id',
            name: 'orders',
          },
          // { TODO: This is left a comment because it will be added in a later moment [J.K]
          //   title: getText("reminders"),
          //   component: Reminders,
          //   testID: "reminders-tab-test-id",
          //   name: "reminders",
          // },
        ]}
      />
    </>
  );
};
export type AccountStackNavigationProp =
  StackNavigationProp<MedicationsNavigationStackParamList>;

export type MedicationsNavigationStackParamList = {
  medications: undefined;
  orders: undefined;
  reminders: undefined;
};
