import {
  PatientRecordDto,
  RecordUnderCareDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { SafeAreaView, ScrollView, TouchableOpacity, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { Text } from 'assets/components/text';
import { Button } from 'assets/components/button';
import { PatientUnderCareForm } from '../../patient-actions';
import { useForm } from 'assets/form';
import {
  PatientUnderCareEditDetailsEnum,
  usePatientUnderCareState,
} from '../../patient-store';
import { IconButton } from 'assets/components/icon-button';
import { CloseIcon } from 'assets/icons';
import { EditPersonalInformation } from '../manage-people-under-care-details/EditPersonalInformation';
import { EditMedicalInformation } from '../manage-people-under-care-details/EditMedicalInformation';
import { UseFormReturn } from 'react-hook-form';
import { PersonalInformationComponent } from '../account-information/PersonalInformationComponent';

export const PersonalInfoBottomSheet: FunctionComponent<
  PropsWithChildren<{
    nativeRef: React.RefObject<BottomSheetModal>;
    setVisibility: (val: boolean) => void;
    handleRemovePatientUnderCare: (record?: RecordUnderCareDto) => void;
    record?: RecordUnderCareDto;
    patientRecord: PatientRecordDto;
    changeViewMode: (val: boolean) => void;
    viewMode: boolean;
    relationshipProps?: Array<{ label: string; value: string }>;
    handleSave: (updatePR: UpdatePatientRecordDto) => void;
    onEditPress: (
      editDetail: PatientUnderCareEditDetailsEnum,
      methods: UseFormReturn<PatientUnderCareForm>,
    ) => UseFormReturn<PatientUnderCareForm>;
    whichDetailsToEdit: (
      editType: PatientUnderCareEditDetailsEnum.PersonalInfo,
    ) => void;
    editDetails: PatientUnderCareEditDetailsEnum;
    form: UseFormReturn<PatientUnderCareForm>;
    title: string;
  }>
> = ({
  nativeRef,
  setVisibility,
  handleRemovePatientUnderCare,
  record,
  patientRecord,
  changeViewMode,
  viewMode,
  relationshipProps,
  handleSave,
  onEditPress,
  whichDetailsToEdit,
  editDetails,
  form,
  title,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { status, error } = usePatientUnderCareState();
  const address = patientRecord.address
    ? `${patientRecord.address.address1} ${patientRecord.address.address2} ${patientRecord.address.city} ${patientRecord.address.state} ${patientRecord.address.postal_code} ${patientRecord.address.country}`.trim()
    : getText('none');

  return (
    <BottomSheet
      bottomSheetRef={nativeRef}
      height={'100%'}
      footerContent={<View></View>}
      onDismiss={() => {}}
      headerContent={
        <View>
          <View style={styles.footerContent}>
            <View style={styles.headerIconStyle}>
              <IconButton
                icon={CloseIcon}
                onPress={() => setVisibility(false)}
                size={20}
                color={theme.palette.gray[500]}
                logger={{
                  id: 'personal-info-close-button',
                }}
              />
            </View>
            <View style={styles.headerText}>
              <Text style={styles.modalHeader}>{title}</Text>
            </View>
          </View>
        </View>
      }
      children={
        <ScrollView style={styles.container}>
          {!viewMode ? (
            <View>
              <PersonalInformationComponent
                patientRecord={patientRecord}
                onEditPress={() => {
                  onEditPress(
                    PatientUnderCareEditDetailsEnum.PersonalInfo,
                    form,
                  );
                }}
              ></PersonalInformationComponent>
              <View style={styles.requestTextContainer}>
                <Text style={styles.title}>
                  {getText('medical-info').toUpperCase()}
                </Text>
                <TouchableOpacity
                  style={styles.textContainer}
                  onPress={() =>
                    onEditPress(
                      PatientUnderCareEditDetailsEnum.MedicalInfo,
                      form,
                    )
                  }
                >
                  <Text
                    style={[styles.pressableTextRevoke, styles.leftSpacing]}
                  >
                    {getText('edit')}
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={{ marginBottom: theme.getSpacing(3) }}>
                <Text style={styles.subtitle}>{getText('allergies')}</Text>
                <Text style={styles.boldSubtitle}>
                  {patientRecord.allergies && patientRecord.allergies.length > 0
                    ? patientRecord.allergies.map((v) => v).join(', ')
                    : getText('none')}
                </Text>
              </View>
              <View style={{ marginBottom: theme.getSpacing(3) }}>
                <Text style={styles.subtitle}>
                  {getText('medical-conditions')}
                </Text>
                <Text style={styles.boldSubtitle}>
                  {patientRecord.medical_conditions &&
                  patientRecord.medical_conditions.length > 0
                    ? patientRecord.medical_conditions.map((v) => v).join(', ')
                    : getText('none')}
                </Text>
              </View>
              <View style={{ marginBottom: theme.getSpacing(3) }}>
                <Text style={styles.subtitle}>
                  {getText('easy-open-bottle-caps')}
                </Text>
                <Text style={styles.boldSubtitle}>
                  {patientRecord.prefers_easy_open_bottle_caps
                    ? getText('yes')
                    : getText('no')}
                </Text>
              </View>
              <Button
                hierarchy="destructive-secondary"
                logger={{
                  id: 'remove-patient-bottom-sheet-button',
                }}
                style={styles.removeButton}
                onPress={() => handleRemovePatientUnderCare(record)}
              >
                {getText('remove')}
              </Button>
            </View>
          ) : PatientUnderCareEditDetailsEnum.PersonalInfo === editDetails ? (
            <EditPersonalInformation
              changeViewMode={changeViewMode}
              relationshipProps={relationshipProps}
              handleSave={handleSave}
              form={form}
            ></EditPersonalInformation>
          ) : PatientUnderCareEditDetailsEnum.MedicalInfo === editDetails ? (
            <EditMedicalInformation
              patientRecord={patientRecord}
              changeViewMode={changeViewMode}
              handleSave={handleSave}
              form={form}
            ></EditMedicalInformation>
          ) : (
            //TODO Insurance and Id info will come here
            <></>
          )}
        </ScrollView>
      }
    ></BottomSheet>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
  },
  removeButton: { width: '100%', marginBottom: theme.getSpacing(3) },
  container: {},
  title: {
    marginBottom: theme.getSpacing(1),
    fontSize: 12,
    lineHeight: 16,
    fontWeight: '800',
  },
  subtitle: {
    fontWeight: '400',
    color: theme.palette.gray[600],
    fontSize: 14,
    lineHeight: 20,
  },
  boldSubtitle: {
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 28,
    color: theme.palette.gray[900],
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(3),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(1),
  },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
  city: {
    flexGrow: 3,
  },
  zip: {
    flexGrow: 2,
  },
  state: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'stretch',
  },
  subTitle: {
    alignSelf: 'flex-start',
    marginBottom: 8,
    fontSize: 16,
  },
  row: {
    alignContent: 'stretch',
  },
  selectStyles: {
    display: 'flex',
    alignItems: 'stretch',
  },
  headerIconStyle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerContent: {
    justifyContent: 'center',
    marginBottom: theme.getSpacing(2),
  },
  headerText: { alignSelf: 'center' },
  modalHeader: {
    lineHeight: 28,
    fontWeight: '600',
    fontSize: 18,
    color: theme.palette.gray[900],
  },
}));
