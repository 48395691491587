import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  View,
  ImageBackground,
  useWindowDimensions,
  Platform,
  Modal,
} from 'react-native';
import theme, { useTheme } from 'assets/theme';
import { StyleSheet } from 'react-native';
import { Text } from 'assets/components/text';
import { useForm } from 'assets/form';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { PatientInsuranceForm } from './patient-actions';
import { AccountStackParamList } from '../AccountNavigation';
import { Camera } from '../../../camera/Camera';
import { AlertTriangleIcon, ImageIcon, TrashIcon } from 'assets/icons';
import type { CameraCapturedPicture } from 'expo-camera';
import { Surface } from 'react-native-paper';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { getText } from 'assets/localization/localization';
import { Form, ScreenContainer } from 'assets/layout';
import { Button } from 'assets/components/button';
import { usePatientIntakeState } from './patient-store';
import { useActionSheet } from '@expo/react-native-action-sheet';
import * as ImagePicker from 'expo-image-picker';
import ImageViewer from 'react-native-image-zoom-viewer';
import { FileUploadIcon } from 'assets/icons/FileUploadIcon';
import { uploadFiles } from './patient-actions';
import { useAppStateStore } from '../../../store/app-store';
import { useUserState } from '../../../store/user-store';
import patientService from '../../../api/patient-service';
import FileStorageService from '../../../api/file-storage-service';
import { PharmacyCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { Alert } from 'assets/components/alert';

export const PatientInsurance: FunctionComponent<PatientInsuranceProps> = ({
  navigation,
  route: {
    params: { callback },
  },
}) => {
  const theme = useTheme();
  const patientId = useUserState.getState().user?.patientRecordId;

  const { status, error } = usePatientIntakeState();

  const [
    { isCameraVisible: isVisible, insuranceCardType },
    setIsCameraVisible,
  ] = useState({ isCameraVisible: false } as {
    isCameraVisible: boolean;
    insuranceCardType?: 'front' | 'back';
  });
  const [errors, setErrors] = useState<CardErrors>();
  const { pharmacyId } = useAppStateStore();
  const { user } = useUserState();

  if (!patientId) {
    throw Error('No patient ID');
  }
  if (!user) {
    throw Error('There is no user data');
  }

  const { preferredPharmacyLocationId } = user;

  if (!preferredPharmacyLocationId) {
    throw Error('No location ID');
  }

  const [frontPhoto, setFrontPhoto] = useState<
    CameraCapturedPicture | any | undefined
  >(undefined);
  const [backPhoto, setBackPhoto] = useState<
    CameraCapturedPicture | any | undefined
  >(undefined);
  const [frontImageViewer, setFrontImageViewer] = useState<boolean>(false);
  const [backImageViewer, setBackImageViewer] = useState<boolean>(false);
  const [frontImageFileName, setFrontImageFileName] = useState<string>();
  const [backImageFileName, setBackImageFileName] = useState<string>();
  const methods = useForm<PatientInsuranceForm>({
    defaultValues: {
      frontPhoto: undefined,
      backPhoto: undefined,
    },
  });

  const setPhotoUrlsFromPatientRecord = async () => {
    try {
      const patientRecord = await patientService.findPatientRecord(patientId);
      if (patientRecord.insurance_card_primary_front_url?.length) {
        const responseReadUrl = await FileStorageService.readUrl(
          PharmacyCategory.InsuranceCard,
          patientRecord.insurance_card_primary_front_url!,
          pharmacyId,
        );
        setFrontPhoto({ uri: responseReadUrl.url });
      }
      if (patientRecord.insurance_card_primary_back_url?.length) {
        const responseReadUrl = await FileStorageService.readUrl(
          PharmacyCategory.InsuranceCard,
          patientRecord.insurance_card_primary_back_url!,
          pharmacyId,
        );
        setBackPhoto({ uri: responseReadUrl.url });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setPhotoUrlsFromPatientRecord();
  }, []);

  const handleCameraClose = () => {
    setIsCameraVisible({ isCameraVisible: false });
  };

  const handleSavePhoto = (photo: CameraCapturedPicture) => {
    if (insuranceCardType === 'back') {
      setBackPhoto(photo);
    } else if (insuranceCardType === 'front') {
      setFrontPhoto(photo);
    }
    setIsCameraVisible({ isCameraVisible: false });
  };

  const handleSubmit = async () => {
    if (!frontPhoto && !backPhoto) {
      setErrors({
        front: getText('front-photo-missing'),
        back: getText('back-photo-missing'),
      });
    } else if (!frontPhoto) {
      setErrors({ front: getText('front-photo-missing') });
    } else if (!backPhoto) {
      setErrors({ back: getText('back-photo-missing') });
    } else {
      const frontBlob = await fetch(frontPhoto.uri).then((r) => r.blob());
      const backBlob = await fetch(backPhoto.uri).then((r) => r.blob());
      try {
        const filenames = await uploadFiles(
          pharmacyId,
          frontImageFileName,
          backImageFileName,
          frontBlob,
          backBlob,
        );
        await patientService.updatePatientRecord(patientId, {
          insurance_card_primary_front_url: filenames.frontImageFileName,
          insurance_card_primary_back_url: filenames.backImageFileName,
        });
        if (callback) {
          callback();
        }
      } catch (error) {
        usePatientIntakeState.setState({
          error: { message: getText('insurance-card-upload-error') },
          status: 'error',
        });
      }
    }
  };

  const { showActionSheetWithOptions } = useActionSheet();

  const handleOpenActionSheet = (side: CardSide) => {
    if (frontPhoto && side === 'front') {
      return setFrontImageViewer(true);
    } else if (backPhoto && side === 'back') {
      return setBackImageViewer(true);
    }
    showActionSheetWithOptions(
      {
        options: [
          getText('take-photo'),
          getText('choose-photo'),
          getText('cancel'),
        ],
        cancelButtonIndex: 2,
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          setIsCameraVisible({
            isCameraVisible: true,
            insuranceCardType: side,
          });
        } else if (buttonIndex === 1) {
          choosePhoto(side);
        }
      },
    );
  };

  const choosePhoto = async (side: CardSide) => {
    let photo = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
    });
    if (!photo.cancelled) {
      side === 'front' ? setFrontPhoto(photo) : setBackPhoto(photo);
    }
  };

  const resetImageSelection = async (side: CardSide) => {
    showActionSheetWithOptions(
      {
        options: [
          getText('take-photo'),
          getText('choose-photo'),
          getText('cancel'),
        ],
        cancelButtonIndex: 2,
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          setIsCameraVisible({
            isCameraVisible: true,
            insuranceCardType: side,
          });
        } else if (buttonIndex === 1) {
          choosePhoto(side);
        }
      },
    );
  };

  const removeSelectedImage = (side: CardSide) => {
    if (side === 'front') {
      setFrontPhoto(undefined);
    } else {
      setBackPhoto(undefined);
    }
  };

  const { width, height } = useWindowDimensions();
  const buttonWidth =
    Platform.OS === 'web'
      ? theme.webMaxWidth - theme.getSpacing(2)
      : width - theme.getSpacing(2);
  const buttonHeight = height * 0.25;

  return (
    <ScreenContainer>
      <View
        style={{
          marginTop: theme.getSpacing(2),
          marginBottom: theme.getSpacing(1),
          borderBottomColor: theme.palette.gray[300],
          borderBottomWidth: 1,
          paddingVertical: theme.getSpacing(1),
        }}
      >
        {error && (
          <View style={{ marginVertical: theme.getSpacing(2) }}>
            <Form.Alert
              visible={!!error}
              intent="warning"
              title={error.message!}
            ></Form.Alert>
          </View>
        )}
        <Text
          style={{
            marginBottom: theme.getSpacing(1),
            fontSize: 16,
          }}
        >
          {getText('insurance-add-your-information')}
        </Text>
      </View>
      <Text
        style={{
          color: theme.palette.gray[600],
          paddingVertical: theme.getSpacing(1),
        }}
      >
        {getText('insurance-take-card-picture')}
      </Text>
      <View
        style={{
          marginTop: theme.getSpacing(2),
        }}
      >
        <Form methods={methods}>
          <View style={stylesButton.imageOptions}>
            <Text style={stylesButton.label}>{getText('front-of-card')}</Text>
            {frontPhoto && (
              <View style={stylesButton.inline}>
                <TouchableOpacity onPress={() => resetImageSelection('front')}>
                  <Text style={stylesButton.replaceText}>
                    {getText('replace')}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => removeSelectedImage('front')}>
                  <TrashIcon size={20} color={theme.palette.gray[500]} />
                </TouchableOpacity>
              </View>
            )}
          </View>
          {errors && errors.front && (
            <Text style={stylesButton.errorLabel}>
              <AlertTriangleIcon color={theme.palette.error[600]} size={14} />{' '}
              {errors.front}
            </Text>
          )}
          <Form.Row>
            <PhotoButton
              uri={frontPhoto?.uri}
              width={buttonWidth}
              height={buttonHeight}
              onPress={() => {
                handleOpenActionSheet('front');
              }}
            >
              <View
                style={{
                  padding: theme.getSpacing(1),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <View>
                  <ImageIcon size={32} color={theme.palette.gray[500]} />
                </View>
                <View style={stylesButton.upload}>
                  <FileUploadIcon color={theme.palette.gray[500]} size={24} />
                  <Text style={stylesButton.uploadText}>
                    {getText('upload')}
                  </Text>
                </View>
              </View>
            </PhotoButton>
          </Form.Row>
          <View style={stylesButton.imageOptions}>
            <Text style={stylesButton.label}>{getText('back-of-card')}</Text>
            {backPhoto && (
              <View style={stylesButton.inline}>
                <TouchableOpacity onPress={() => resetImageSelection('back')}>
                  <Text style={stylesButton.replaceText}>
                    {getText('replace')}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => removeSelectedImage('back')}>
                  <TrashIcon size={20} color={theme.palette.gray[500]} />
                </TouchableOpacity>
              </View>
            )}
          </View>
          {errors && errors.back && (
            <Text style={stylesButton.errorLabel}>
              {' '}
              <AlertTriangleIcon
                color={theme.palette.error[600]}
                size={14}
              />{' '}
              {errors.back}
            </Text>
          )}
          <Form.Row>
            <PhotoButton
              uri={backPhoto?.uri}
              width={buttonWidth}
              height={buttonHeight}
              onPress={() => {
                handleOpenActionSheet('back');
              }}
            >
              <View
                style={{
                  padding: theme.getSpacing(1),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <View>
                  <ImageIcon size={32} color={theme.palette.gray[500]} />
                </View>
                <View style={stylesButton.upload}>
                  <FileUploadIcon color={theme.palette.gray[500]} size={24} />
                  <Text style={stylesButton.uploadText}>
                    {getText('upload')}
                  </Text>
                </View>
              </View>
            </PhotoButton>
          </Form.Row>
        </Form>
        <Camera
          title={
            insuranceCardType === 'front'
              ? getText('add-front-of-card')
              : getText('add-back-of-card')
          }
          onSave={handleSavePhoto}
          isVisible={isVisible}
          onClose={handleCameraClose}
          mask={'card'}
        />
      </View>
      <Form.Actions>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('home');
          }}
        >
          <Text
            style={{
              color: theme.palette.gray[700],
              fontSize: 16,
              marginTop: theme.getSpacing(1),
              marginBottom: theme.getSpacing(3),
              alignSelf: 'center',
            }}
          >
            {getText('insurance-bring-to-store')}
          </Text>
        </TouchableOpacity>
        <Button
          onPress={handleSubmit}
          hierarchy="primary"
          loading={status === 'loading'}
          logger={{ id: 'patient-insurance-submit-button' }}
        >
          {getText('next')}
        </Button>
      </Form.Actions>
      <Modal visible={frontImageViewer}>
        {Platform.OS === 'web' && (
          <View style={stylesButton.closeButtonContainer}>
            <TouchableOpacity
              onPress={() => setFrontImageViewer(!frontImageViewer)}
              style={stylesButton.closeModalButton}
            >
              <Text style={{ color: theme.palette.white }}>
                {getText('close')}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <ImageViewer
          imageUrls={[{ url: frontPhoto?.uri! }]}
          index={0}
          onSwipeDown={() => setFrontImageViewer(false)}
          enableSwipeDown={true}
        />
      </Modal>

      <Modal visible={backImageViewer}>
        {Platform.OS === 'web' && (
          <View style={stylesButton.closeButtonContainer}>
            <TouchableOpacity
              style={stylesButton.closeModalButton}
              onPress={() => setBackImageViewer(!backImageViewer)}
            >
              <Text style={{ color: theme.palette.white }}>
                {getText('close')}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <ImageViewer
          imageUrls={[{ url: backPhoto?.uri! }]}
          index={0}
          onSwipeDown={() => setBackImageViewer(false)}
          enableSwipeDown={true}
        />
      </Modal>
    </ScreenContainer>
  );
};

interface PatientInsuranceProps
  extends NativeStackScreenProps<AccountStackParamList, 'patient-insurance'> {}

const PhotoButton: FunctionComponent<
  PropsWithChildren<{
    uri?: string;
    width?: string | number;
    height?: string | number;
    onPress: () => void;
  }>
> = ({ uri, width = 100, height = 100, onPress, children }) => {
  const theme = useTheme();
  const innerContent = !!uri ? (
    <ImageBackground
      source={{ uri: uri }}
      resizeMode="contain"
      style={{ height: height }}
    />
  ) : (
    children
  );

  return (
    <Surface
      style={[
        stylesButton.button,
        {
          elevation: 0,
          borderRadius: theme.roundness,
          height: height,
          justifyContent: 'center',
          overflow: 'hidden',
          backgroundColor: theme.palette.gray[100],
          width: width,
          minHeight: height,
        },
      ]}
    >
      <TouchableOpacity
        style={{
          justifyContent: 'center',
          height: height,
        }}
        onPress={onPress}
      >
        {innerContent}
      </TouchableOpacity>
    </Surface>
  );
};

const stylesButton = StyleSheet.create({
  button: {
    minWidth: 64,
    borderStyle: 'solid',
  },
  closeModalButton: {
    padding: theme.getSpacing(1),
    backgroundColor: theme.palette.error[600],
    borderRadius: 5,
    margin: theme.getSpacing(1),
  },
  closeButtonContainer: {
    width: 70,
  },
  label: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    textAlign: 'left',
  },
  imageOptions: {
    marginBottom: theme.getSpacing(1),
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  upload: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
  uploadText: {
    color: theme.palette.gray[500],
    marginLeft: theme.getSpacing(1),
    fontSize: 16,
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  replaceText: {
    color: theme.colors.primary,
    fontSize: 14,
    fontWeight: '500',
    marginRight: 12,
  },
  errorLabel: {
    color: theme.palette.error[600],
    fontSize: 14,
    fontWeight: '400',
  },
});

type CardSide = 'front' | 'back';
type CardErrors = {
  front?: string;
  back?: string;
};
