import { BookingDto } from '@digitalpharmacist/appointment-service-client-axios';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { AlertTriangleIcon, CheckIcon, CloseIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { formatDateTimeWithTimezone } from '../../common/datetime-utils';
import {
  APPOINTMENT_DATE_TIME_FORMAT,
  DEFAULT_UTC_OFFSET,
} from '../appointments/book-appointment/book-appointment-utils';

export const CancelAppointmentResult: FunctionComponent<
  CancelAppointmentResultProps
> = ({ booking }) => {
  const error = false;
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={styles.circleIcon}>
        {!error ? (
          <CloseIcon size={20} color={theme.palette.error[600]} />
        ) : (
          <AlertTriangleIcon size={20} color={theme.palette.error[600]} />
        )}
      </View>
      <Text style={styles.title}>
        {!error ? (
          <>
            {booking.title} {getText('appointment-canceled')}
          </>
        ) : (
          getText('cancel-error')
        )}
      </Text>
      <View style={styles.infoContainer}>
        <Text style={styles.info}>{getText('previously-scheduled')}</Text>
        <Text style={styles.info}>
          {formatDateTimeWithTimezone(
            booking.startTime,
            DEFAULT_UTC_OFFSET,
            APPOINTMENT_DATE_TIME_FORMAT,
          )}
        </Text>
      </View>
      <View style={styles.noteContainer}>
        <Text style={styles.note}>{getText('cancel-note')}</Text>
      </View>
    </View>
  );
};

export interface CancelAppointmentResultProps {
  booking: BookingDto;
}

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    alignItems: 'center',
    flex: 1,
    marginTop: theme.getSpacing(4),
  },
  circleIcon: {
    width: 46,
    height: 46,
    borderRadius: 46,
    backgroundColor: theme.palette.error[100],
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(4),
    textAlign: 'center',
  },
  infoContainer: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(4),
  },
  info: {
    fontSize: 16,
    color: theme.palette.gray[600],
    textAlign: 'center',
  },
  noteContainer: {
    marginTop: 'auto',
    marginBottom: theme.getSpacing(4),
  },
  note: {
    fontSize: 12,
    color: theme.palette.gray[700],
    textAlign: 'center',
  },
}));
