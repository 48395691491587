import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ScreenSafeContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { Surface } from 'react-native-paper';
import {
  PatientUnderCareEditDetailsEnum,
  usePatientUnderCareState,
} from './patient-store';
import { PatientUnderCareStackParamList } from './PatientUnderCareNavigation';
import {
  PatientUnderCareForm,
  refreshCaregiverRequestsState,
} from './patient-actions';
import { PersonalInfoBottomSheet } from './components/record-under-care-info/PatientPersonalInfoBottomSheet';
import {
  PatientRecordDto,
  PatientUnderCareRelationship,
  RecordUnderCareDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import patientService from '../../../api/patient-service';
import { DateTimeFormat, formatDateAsString } from '../../../common/form-utils';
import { PersonalInfoModal } from './components/record-under-care-info/PatientPersonalInfoModal';
import { UseFormReturn, useForm } from 'react-hook-form';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { AsyncStatus } from '../../../store/types';

export const PatientUnderCareList: FunctionComponent<
  PropsWithChildren<PatientUnderCareListProps>
> = () => {
  const theme = useTheme();
  const styles = useStyles();
  const { recordsUnderCare, caregiverRequests, status } =
    usePatientUnderCareState();

  useEffect(() => {
    refreshCaregiverRequestsState();
    usePatientUnderCareState.setState({ status: 'success' });
  }, []);

  return (
    <ScreenSafeContainer style={styles.container}>
      {recordsUnderCare.map((record) => (
        <View key={record.id} style={styles.container}>
          <InfoCard
            name={`${record.record_under_care.first_name} ${record.record_under_care.last_name}`.trim()}
            date={formatDateAsString(
              record.record_under_care.date_of_birth,
              DateTimeFormat.USDateFormat,
            )}
            relationship={record.relationship}
            submittedDate={record.created_at}
            isRequest={false}
            record={record}
            status={status}
          />
        </View>
      ))}
      {caregiverRequests.map((caregiver) => (
        <View key={caregiver.id} style={styles.container}>
          <InfoCard
            name={`${caregiver.first_name} ${caregiver.last_name}`.trim()}
            date={formatDateAsString(
              caregiver.date_of_birth,
              DateTimeFormat.USDateFormat,
            )}
            relationship={caregiver.relationship}
            isRequest={true}
            status={status}
          />
        </View>
      ))}
    </ScreenSafeContainer>
  );
};

const InfoCard: FunctionComponent<
  PropsWithChildren<{
    name?: string;
    date?: string;
    relationship?: string;
    submittedDate?: string; //leaving this but in the new figma design we don't show submitted data like we did earlier
    isRequest: boolean;
    record?: RecordUnderCareDto;
    status: AsyncStatus;
  }>
> = ({ name, date, relationship, isRequest, record, status }) => {
  const theme = useTheme();
  const styles = useStyles();
  const isWeb = Platform.OS === 'web';
  const nativeRef = React.useRef<BottomSheetModal>(null);
  const [showModal, setShowModal] = useState(false);
  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();
  const [viewMode, switchViewMode] = useState(false);
  const [title, setTitle] = useState(getText('personal-info'));
  const [editDetails, setWhichEditDetails] = useState(
    PatientUnderCareEditDetailsEnum.PersonalInfo,
  );
  const methods = useForm<PatientUnderCareForm>({});
  const [form, setForm] =
    useState<UseFormReturn<PatientUnderCareForm>>(methods);

  const handleRemovePatientUnderCare = async (record?: RecordUnderCareDto) => {
    // TODO Do we need error handling? This probably applies more broadly to these types of calls. (Error handling)
    if (!record) throw new Error('No patient record has been found');
    await patientService.removeRecordUnderCare(record.caregiver_id, record.id);
    await refreshCaregiverRequestsState();
    await setVisibility(false);
  };

  const setVisibility = async (visible: boolean) => {
    if (visible) await refreshPatientRecord();
    else {
      changeViewMode(false);
    }
    if (Platform.OS === 'web') {
      setShowModal(visible);
    } else {
      if (!visible) {
        nativeRef.current?.dismiss();
      } else {
        nativeRef.current?.present();
      }
    }
  };

  const refreshPatientRecord = async () => {
    if (!record?.record_under_care) throw new Error('No record id');
    else {
      setPatientRecord(
        await patientService.findPatientRecord(record.record_under_care.id),
      );
    }
  };

  const updatePatientRecord = async (updatePR: UpdatePatientRecordDto) => {
    await refreshCaregiverRequestsState();
    if (patientRecord)
      setPatientRecord(
        await patientService.updatePatientRecord(patientRecord.id, updatePR),
      );
  };

  const handleSave = (updatePR: UpdatePatientRecordDto) => {
    updatePatientRecord(updatePR).catch((error) => {
      alert(error); //TODO Error handling
    });
  };

  const whichDetailsToEdit = (editType: PatientUnderCareEditDetailsEnum) => {
    if (editType === PatientUnderCareEditDetailsEnum.PersonalInfo) {
      setTitle(getText('personal-info'));
    } else if (editType === PatientUnderCareEditDetailsEnum.MedicalInfo) {
      setTitle(getText('medical-info'));
    }
    setWhichEditDetails(editType);
  };

  const onEditPress = (
    editDetail: PatientUnderCareEditDetailsEnum,
    methods: UseFormReturn<PatientUnderCareForm>,
  ) => {
    if (patientRecord?.first_name)
      methods.setValue('first_name', patientRecord.first_name);
    if (patientRecord?.last_name)
      methods.setValue('last_name', patientRecord.last_name);
    if (patientRecord?.date_of_birth)
      methods.setValue(
        'date_of_birth',
        formatDateAsString(
          patientRecord.date_of_birth,
          DateTimeFormat.USDateFormat,
        ),
      );
    if (record?.relationship)
      methods.setValue('relationship', record.relationship);
    if (patientRecord?.gender) methods.setValue('gender', patientRecord.gender);
    if (patientRecord?.address) {
      methods.setValue(
        'address1',
        patientRecord.address.address1 ? patientRecord.address.address1 : '',
      );
      methods.setValue(
        'address2',
        patientRecord.address.address2 ? patientRecord.address.address2 : '',
      );
      methods.setValue(
        'city',
        patientRecord.address.city ? patientRecord.address.city : '',
      );
      methods.setValue(
        'state',
        patientRecord.address.state ? patientRecord.address.state : '',
      );
      methods.setValue(
        'country',
        patientRecord.address.country ? patientRecord.address.country : '',
      );
      methods.setValue(
        'postal_code',
        patientRecord.address.postal_code
          ? patientRecord.address.postal_code
          : '',
      );
    } else {
      methods.setValue('address1', '');
      methods.setValue('address2', '');
      methods.setValue('city', '');
      methods.setValue('state', '');
      methods.setValue('country', '');
      methods.setValue('postal_code', '');
    }
    if (patientRecord?.medical_conditions)
      methods.setValue(
        'medical_conditions',
        patientRecord.medical_conditions.map((v) => {
          return { value: v, text: v };
        }),
      );
    if (patientRecord?.allergies) {
      if (patientRecord.allergies.length > 0) {
        methods.setValue(
          'allergies',
          patientRecord.allergies.map((v) => {
            return { value: v, text: v };
          }),
        );
      } else {
        methods.setValue('allergies', [
          { value: 'no_allergies', text: getText('no-allergies') },
        ]);
      }
    }
    if (patientRecord?.prefers_easy_open_bottle_caps) {
      patientRecord.prefers_easy_open_bottle_caps
        ? methods.setValue('prefers_easy_open_bottle_caps_str', getText('yes'))
        : methods.setValue('prefers_easy_open_bottle_caps_str', getText('no'));
    }
    changeViewMode(true);
    whichDetailsToEdit(editDetail);
    setForm(methods);
    return methods;
  };

  const relationshipProps: Array<{ label: string; value: string }> = [
    {
      label: getText('child'),
      value: PatientUnderCareRelationship.Child,
    },
    {
      label: getText('spouse'),
      value: PatientUnderCareRelationship.Spouse,
    },
    {
      label: getText('parent'),
      value: PatientUnderCareRelationship.Parent,
    },
    {
      label: getText('grandparent'),
      value: PatientUnderCareRelationship.Grandparent,
    },
    {
      label: getText('grandchild'),
      value: PatientUnderCareRelationship.Grandchild,
    },
    {
      label: getText('partner'),
      value: PatientUnderCareRelationship.Partner,
    },
    {
      label: getText('pet'),
      value: PatientUnderCareRelationship.Pet,
    },
    {
      label: getText('other'),
      value: PatientUnderCareRelationship.Other,
    },
  ];

  const changeViewMode = (editMode: boolean) => {
    switchViewMode(editMode);
  };

  if (status !== 'success') {
    return <LoadingIndicator></LoadingIndicator>;
  } else {
    return (
      <Surface
        style={[
          {
            elevation: 2,
            overflow: 'hidden',
            backgroundColor: theme.palette.white,
            minHeight: 100,
            width: '100%',
            marginBottom: theme.getSpacing(1),
          },
        ]}
      >
        <View style={{ margin: theme.getSpacing(2) }}>
          <View style={styles.requestTextContainer}>
            <Text
              style={{
                fontWeight: '600',
                fontSize: 24,
                lineHeight: 26,
                color: theme.palette.gray[700],
              }}
            >
              {name}
            </Text>
            {!isRequest && (
              <TouchableOpacity
                style={styles.textContainer}
                onPress={() => setVisibility(true)}
              >
                <Text
                  style={[
                    styles.pressableTextRevoke,
                    styles.leftSpacing,
                    isWeb && styles.pointer,
                  ]}
                >
                  {getText('details')}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={{
                color: theme.palette.gray[700],
                marginTop: theme.getSpacing(1),
              }}
            >
              {date}
            </Text>
            <Text
              style={{
                paddingLeft: theme.getSpacing(0.5),
                color: theme.palette.gray[700],
                marginTop: theme.getSpacing(1),
              }}
            >
              {'('}
              {relationship}
              {')'}
            </Text>
          </View>
        </View>
        {patientRecord &&
          (Platform.OS === 'web' ? (
            <PersonalInfoModal
              showModal={showModal}
              setVisibility={setVisibility}
              handleRemovePatientUnderCare={handleRemovePatientUnderCare}
              record={record}
              patientRecord={patientRecord}
              changeViewMode={changeViewMode}
              viewMode={viewMode}
              relationshipProps={relationshipProps}
              handleSave={handleSave}
              onEditPress={onEditPress}
              whichDetailsToEdit={whichDetailsToEdit}
              editDetails={editDetails}
              title={title}
              form={form}
            ></PersonalInfoModal>
          ) : (
            <PersonalInfoBottomSheet
              nativeRef={nativeRef}
              setVisibility={setVisibility}
              handleRemovePatientUnderCare={handleRemovePatientUnderCare}
              record={record}
              relationshipProps={relationshipProps}
              changeViewMode={changeViewMode}
              viewMode={viewMode}
              patientRecord={patientRecord}
              handleSave={handleSave}
              onEditPress={onEditPress}
              whichDetailsToEdit={whichDetailsToEdit}
              editDetails={editDetails}
              title={title}
              form={form}
            ></PersonalInfoBottomSheet>
          ))}
      </Surface>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.getSpacing(2),
    alignSelf: 'center',
    backgroundColor: theme.palette.gray[100],
    width: '100%',
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 18,
    marginTop: theme.getSpacing(3),
    lineHeight: 28,
  },
  text: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    marginTop: theme.getSpacing(2),
    paddingStart: theme.getSpacing(1),
    paddingEnd: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: { flexDirection: 'row', alignItems: 'center' },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
  pointer: {
    //@ts-ignore
    [Platform.OS === 'web' ? 'cursor' : undefined]: 'pointer', // only for web purposes
  },
}));

type PatientUnderCareListProps = NativeStackScreenProps<
  PatientUnderCareStackParamList,
  'patient-under-care-list'
>;
