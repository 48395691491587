import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { Button } from 'assets/components/button';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { View } from 'react-native';
import {
  AuthStackNavigationProp,
  AuthStackParamList,
} from '../../navigation/AuthNavigation';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { TextField } from 'assets/components/text-field';
import { login, LoginForm } from './login-actions';
import { useForm } from 'assets/form';
import { LoginTestIDs } from './LoginTestIDs';
import { useLoginState } from './login-store';
import { ScreenContainer, Form } from 'assets/layout';
import { GoogleSignInButton } from '../../components/google-sign-in-button/GoogleSignInButton';
import { AppleSignInButton } from '../../components/apple-sign-in-button/AppleSignInButton';
import { useResetPasswordState } from '../reset-password/reset-password-store';
import { Logo } from '../../components/logo/Logo';
import isEmail from 'validator/lib/isEmail';
import { useNavigation } from '@react-navigation/native';

export const Login: FunctionComponent<PropsWithChildren<LoginProps>> = () => {
  const navigation = useNavigation<AuthStackNavigationProp>();
  const { status, error, email } = useLoginState();
  const theme = useTheme();
  const styles = useStyles();
  const methods = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (email) {
      methods.setValue('email', email as string);
    }
  }, []);

  const handleGoBackPress = () => {
    navigation.navigate('intro');
  };

  const handleForgotPassword = () => {
    useResetPasswordState.setState({ status: 'idle', error: undefined });
    navigation.navigate('reset-link');
  };

  const handleSubmit = async () => {
    login(methods.getValues(), navigation);
  };

  useEffect(() => {
    return () => {
      useLoginState.setState({ error: undefined, status: 'idle' });
    };
  }, []);

  return (
    <>
      <View style={styles.container}>
        <ScreenContainer backgroundColor={theme.colors.brandedPrimary}>
          <Logo />
          <Form methods={methods}>
            <Form.Alert
              title={error?.message}
              intent="error"
              visible={!!error}
            />
            <Form.Row>
              <Form.Column>
                <TextField
                  label={getText('email')}
                  placeholderColor={theme.palette.white}
                  textColor={theme.palette.white}
                  activeBorderColor={theme.palette.white}
                  inactiveBorderColor={theme.palette.white}
                  disabledBorderColor={theme.palette.white}
                  backgroundColor={theme.colors.brandedPrimary}
                  labelColor={theme.palette.white}
                  name="email"
                  rules={{
                    required: getText('email-is-required'),
                    validate: {
                      value: () => {
                        return isEmail(methods.getValues().email)
                          ? true
                          : getText('email-is-not-valid');
                      },
                    },
                  }}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={true}
                  testID={LoginTestIDs.usernameInput}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  label={getText('password')}
                  placeholderColor={theme.palette.white}
                  textColor={theme.palette.white}
                  iconColor={theme.palette.white}
                  activeBorderColor={theme.palette.white}
                  inactiveBorderColor={theme.palette.white}
                  disabledBorderColor={theme.palette.white}
                  backgroundColor={theme.colors.brandedPrimary}
                  labelColor={theme.palette.white}
                  type="password"
                  name="password"
                  rules={{
                    required: getText('password-is-required'),
                  }}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={status === 'loading'}
                  testID={LoginTestIDs.passwordInput}
                />
              </Form.Column>
            </Form.Row>
            <Form.Actions>
              <Button
                testID={LoginTestIDs.loginButton}
                onPress={methods.handleSubmit(handleSubmit)}
                hierarchy="secondary"
                loading={status === 'loading'}
                logger={{ id: 'login-button' }}
                style={styles.loginButton}
              >
                {getText('login')}
              </Button>

              <Button
                hierarchy="primary"
                testID={LoginTestIDs.goBackButton}
                onPress={handleGoBackPress}
                logger={{ id: 'go-back-button' }}
              >
                {getText('go-back')}
              </Button>

              <Button
                hierarchy="primary"
                onPress={handleForgotPassword}
                logger={{ id: 'forgot-password-button' }}
                style={{ marginBottom: theme.getSpacing(4) }}
              >
                {getText('forgot-password')}
              </Button>
            </Form.Actions>
          </Form>
        </ScreenContainer>
        <View style={styles.footer}>
          <AppleSignInButton
            text={getText('apple-login')}
            style={styles.appleButton}
          />
          <GoogleSignInButton text={getText('google-login')} />
        </View>
      </View>
    </>
  );
};

interface LoginProps
  extends NativeStackScreenProps<AuthStackParamList, 'login'> {}

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.brandedPrimary,
  },
  loginButton: {
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
  },
  footer: {
    backgroundColor: theme.palette.white,
    borderRadius: theme.roundness,
    padding: theme.getSpacing(2),
  },
  appleButton: {
    marginBottom: theme.getSpacing(2),
  },
}));
