import { getLocale } from 'assets/localization/localization';
import moment, { Moment } from 'moment';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY',
  DEFAULT_DATE_TIME_API_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  DEFAULT_TIME_FORMAT = 'hh:mm A';

// Format date to the format expected by the API
export const formatDateTimeApi = (dateToFormat: Moment): string => {
  return moment.utc(dateToFormat).format(DEFAULT_DATE_TIME_API_FORMAT);
};

// Format date with timezone as UTC offset
export const formatDateTimeWithTimezone = (
  dateToFormat: string,
  utcOffset: string,
  format: string,
): string => {
  return moment(dateToFormat).utcOffset(utcOffset).format(format);
};

/**
 * Formats a date string to the preferred format
 */
export const formatDate = (
  valueToFormat: string,
  format: string = DEFAULT_DATE_FORMAT,
) => {
  return moment(valueToFormat).format(format);
};

export const timeAugmenter = (
  value: Date | number | undefined,
  unit: moment.unitOfTime.DurationConstructor,
  quantity: number,
) => {
  return moment(value).add(quantity, unit).toDate();
};

// Format date string to MMM D format based on language
export const formatDateToMMMD = (dateValue: string): string => {
  const momentDate = moment(dateValue);
  const formattedDate = [
    momentDate.toDate().toLocaleString(getLocale(), {
      month: 'short',
    }),
    momentDate.toDate().getDate(),
  ].join(' ');

  return formattedDate;
};
