import { getText } from 'assets/localization/localization';
import { AuthStackNavigationProp } from '../../navigation/AuthNavigation';
import { useAppStateStore } from '../../store/app-store';
import { useUserState } from '../../store/user-store';
import {
  useResendVerificationState,
  useVerificationState,
} from './verification-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from '../../../enums/storage-keys';
import UsersServiceInstance from '../../api/users-service';
import {
  PatientUserDto,
  UserDetails,
  UserLoginResponse,
} from '@digitalpharmacist/users-service-client-axios';

export const verify = async (
  values: { confirmCode: string },
  type: 'email' | 'phone',
  navigation: AuthStackNavigationProp,
  emailAddress?: string,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  const email = useUserState.getState().user?.email!;
  const password = await AsyncStorage.getItem(StorageKeys.Password);
  useVerificationState.setState({ error: undefined, status: 'loading' });
  useResendVerificationState.setState({ error: undefined, status: 'idle' });

  try {
    if (type === 'email') {
      const userVerification = {
        ...values,
        pharmacyId: pharmacyId,
        email: emailAddress ? emailAddress : email,
        password: password,
      };
      const userLoginResponse: UserLoginResponse =
        await UsersServiceInstance.userPatientAccountVerificationPost(
          userVerification,
        );
      if (!userLoginResponse.patient_user) {
        throw new Error(getText('unable-to-complete-verification'));
      }
      const user: PatientUserDto = userLoginResponse.patient_user;
      useVerificationState.setState({ status: 'success' });
      useUserState.setState({ user: user });
      await AsyncStorage.removeItem(StorageKeys.Password);
      if (!userLoginResponse?.accessToken) {
        throw new Error(getText('unable-to-complete-verification'));
      }
      await AsyncStorage.setItem(
        StorageKeys.AccessToken,
        userLoginResponse?.accessToken,
      );
      navigation.navigate('register-details');
    } else if (type === 'phone') {
      const userVerification = {
        ...values,
        pharmacyId: pharmacyId,
        email: email,
      };
      const token = (await AsyncStorage.getItem(StorageKeys.AccessToken)) || '';
      const user: PatientUserDto = await UsersServiceInstance.verifyPhoneNumber(
        token,
        userVerification,
      );
      useVerificationState.setState({ status: 'success' });
      useUserState.setState({ user: user });
      if (!user.preferredPharmacyLocationId) {
        navigation.navigate('patient-stores');
      }
    }
  } catch (e) {
    useVerificationState.setState({
      error: { message: getText('unable-to-complete-verification') },
      status: 'error',
    });
  }
};

export const resendEmailConfirmationCode = async (): Promise<void> => {
  const email = useUserState.getState().user?.email!;
  const pharmacyId = useAppStateStore.getState().pharmacyId!;
  useResendVerificationState.setState({ error: undefined, status: 'loading' });

  try {
    const userDetails: UserDetails = {
      email: email,
      pharmacyId: pharmacyId,
    };
    await UsersServiceInstance.userPatientResendConfirmationCodePost(
      userDetails,
    );
    useResendVerificationState.setState({ status: 'success' });
  } catch (e) {
    useResendVerificationState.setState({
      error: {
        message: getText('unable-to-resend-verification-code'),
      },
      status: 'error',
    });
  }
};

export const resendPhoneConfirmationCode = async (): Promise<void> => {
  useResendVerificationState.setState({ error: undefined, status: 'loading' });

  try {
    await UsersServiceInstance.userPatientResendPhoneNumberConfirmationCodePost();
    useResendVerificationState.setState({ status: 'success' });
  } catch (e) {
    useResendVerificationState.setState({
      error: {
        message: getText('unable-to-resend-verification-code'),
      },
      status: 'error',
    });
  }
};

export interface PhoneEmailVerification {
  email?: string;
  confirmCode: string;
  pharmacyId: string;
}
