import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { Platform, View } from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ScreenSafeContainer, Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import {
  PharmacyLocationDto,
  TimeRangeDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { ListAccordion } from 'assets/components/list-accordion';
import { ScrollView } from 'react-native-gesture-handler';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import {
  AuthStackNavigationProp,
  AuthStackParamList,
} from '../../navigation/AuthNavigation';
import { StoreInformation } from '../../components/store-selector/StoreInformation';
import { MapSize } from '../../components/store-selector/types';
import { useAppStateStore } from '../../store/app-store';
import { IconButton } from 'react-native-paper';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { updateLocationDetails } from './register-actions';
export const RegisterStoreDetails: FunctionComponent<
  PropsWithChildren<RegisterStoreDetailsProps>
> = ({ route }) => {
  const theme = useTheme();
  const styles = useStyles();
  const isFocused = useIsFocused();
  const { storeId } = route.params as any;
  const navigation = useNavigation<AuthStackNavigationProp>();
  const {
    stores,
    locationPharmacyHours,
    locationStoreHours,
    locationLanguages,
    locationServices,
  } = useAppStateStore();
  const [currentStore, setCurrentStore] = useState<PharmacyLocationDto>();
  const { top } = useSafeAreaInsets();
  const [storesAccordionView, setStoresAccordionView] = useState(false);
  const [pharmaciesAccordionView, setPharmaciesAccordionView] = useState(false);
  const handleOnChange = (value: PharmacyLocationDto) => {
    navigation.navigate('patient-stores');
  };
  const day = new Date().getDay();

  useEffect(() => {
    updateLocationDetails(storeId);
  }, []);

  useEffect(() => {
    setCurrentStore(stores.find((x) => x.id === (storeId as string)));
  }, [storeId, stores, isFocused]);

  const handleAccordionStoreExpand = () => {
    setStoresAccordionView(!storesAccordionView);
  };

  const handleAccordionPharmacyExpand = () => {
    setPharmaciesAccordionView(!pharmaciesAccordionView);
  };

  const currentDayOpenStoreHours = () => {
    const todayOpenStoreHours = locationStoreHours.find((openHour) => {
      return openHour.day === day;
    });
    return `${todayOpenStoreHours?.start_hour}:00-  ${todayOpenStoreHours?.end_hour}:00`.trim();
  };

  const currentDayOpenPharmacyHours = () => {
    const todayOpenPharmacyHours = locationPharmacyHours.find((openHour) => {
      return openHour.day === day;
    });
    return `${todayOpenPharmacyHours?.start_hour}:00-  ${todayOpenPharmacyHours?.end_hour}:00`.trim();
  };

  return (
    <ScreenSafeContainer
      style={[Platform.OS === 'ios' && { paddingTop: -top }]}
    >
      <ScrollView>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <Text
            style={{
              color: theme.palette.gray[900],
              fontSize: 18,
              lineHeight: 28,
              fontWeight: '600',
              paddingTop: theme.getSpacing(2),
              paddingBottom: theme.getSpacing(2),
              textAlign: 'center',
              flex: 1,
            }}
          >
            {getText('store-info')}
          </Text>
          <IconButton
            onPress={() => navigation.goBack()}
            icon={CloseIcon}
            color={theme.palette.gray[500]}
          />
        </View>
        {currentStore && (
          <Form.Row>
            <Form.Column>
              <StoreInformation
                item={currentStore}
                onChangePress={handleOnChange}
                mapProps={{
                  size: MapSize.md,
                }}
              />
            </Form.Column>
          </Form.Row>
        )}
        {locationStoreHours.length > 0 && (
          <Form.Row>
            <Form.Column>
              <View
                style={{
                  borderBottomColor: theme.palette.gray[300],
                  borderBottomWidth: 1,
                  marginBottom: theme.getSpacing(1),
                  marginTop: theme.getSpacing(2),
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    paddingTop: theme.getSpacing(2),
                    paddingBottom: theme.getSpacing(2),
                  }}
                >
                  {getText('store-hours')}
                </Text>
              </View>
              <Form.Row>
                <Form.Column>
                  {/*TODO: Add actual directions from Figma here, this is just a placeholder*/}
                  <ListAccordion
                    title={getText('open-hours', {
                      hours: currentDayOpenStoreHours(),
                    })}
                    backgroundColor={theme.palette.white}
                    titleStyle={styles.titleStyle}
                    listStyle={styles.listStyle}
                    expanded={storesAccordionView}
                    onPress={handleAccordionStoreExpand}
                  >
                    <Text style={{ color: theme.palette.gray[700] }}>
                      {getText('holiday-hours-vary')}
                    </Text>
                    {locationStoreHours.map((storeHours) => (
                      <View style={styles.hoursStyle} key={storeHours.id}>
                        <Text style={{ color: theme.palette.gray[700] }}>
                          {(() => {
                            switch (storeHours.day) {
                              case 1:
                                return 'Monday';
                              case 2:
                                return 'Tuesday';
                              case 3:
                                return 'Wednesday';
                              case 4:
                                return 'Thursday';
                              case 5:
                                return 'Friday';
                              case 6:
                                return 'Saturday';
                              case 7:
                                return 'Sunday';
                              default:
                                return '';
                            }
                          })()}
                        </Text>
                        <Text style={{ color: theme.palette.gray[700] }}>
                          {storeHours.start_hour} {':00-'}
                          {storeHours.end_hour} {':00'}
                        </Text>
                      </View>
                    ))}
                  </ListAccordion>
                </Form.Column>
              </Form.Row>
            </Form.Column>
          </Form.Row>
        )}
        {locationPharmacyHours.length > 0 && (
          <Form.Row>
            <Form.Column>
              <View
                style={{
                  borderBottomColor: theme.palette.gray[300],
                  borderBottomWidth: 1,
                  marginBottom: theme.getSpacing(1),
                  marginTop: theme.getSpacing(2),
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    paddingTop: theme.getSpacing(2),
                    paddingBottom: theme.getSpacing(2),
                  }}
                >
                  {'Pharmacy Hours'}
                </Text>
              </View>
              <Form.Row>
                <Form.Column>
                  {/*TODO: Add actual directions from Figma here, this is just a placeholder*/}
                  {/* {handleExpandListAccordion && ( */}
                  <ListAccordion
                    title={getText('open-hours', {
                      hours: currentDayOpenPharmacyHours(),
                    })}
                    backgroundColor={theme.palette.white}
                    titleStyle={styles.titleStyle}
                    listStyle={styles.listStyle}
                    expanded={pharmaciesAccordionView}
                    onPress={handleAccordionPharmacyExpand}
                  >
                    <Text style={{ color: theme.palette.gray[700] }}>
                      {getText('holiday-hours-vary')}
                    </Text>
                    {locationPharmacyHours.map((storeHours) => (
                      <View style={styles.hoursStyle} key={storeHours.id}>
                        <Text style={{ color: theme.palette.gray[700] }}>
                          {(() => {
                            switch (storeHours.day) {
                              case 1:
                                return 'Monday';
                              case 2:
                                return 'Tuesday';
                              case 3:
                                return 'Wednesday';
                              case 4:
                                return 'Thursday';
                              case 5:
                                return 'Friday';
                              case 6:
                                return 'Saturday';
                              case 7:
                                return 'Sunday';
                              default:
                                return '';
                            }
                          })()}
                        </Text>
                        <Text style={{ color: theme.palette.gray[700] }}>
                          {storeHours.start_hour} {':00-'}
                          {storeHours.end_hour} {':00'}
                        </Text>
                      </View>
                    ))}
                  </ListAccordion>
                  {/* )} */}
                </Form.Column>
              </Form.Row>
            </Form.Column>
          </Form.Row>
        )}
        {locationLanguages.length > 0 && (
          <Form.Row>
            <Form.Column>
              <View
                style={{
                  borderBottomColor: theme.palette.gray[300],
                  borderBottomWidth: 1,
                  marginBottom: theme.getSpacing(1),
                  marginTop: theme.getSpacing(2),
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    paddingTop: theme.getSpacing(2),
                    paddingBottom: theme.getSpacing(2),
                  }}
                >
                  {getText('languages-spoken')}
                </Text>
              </View>
              <Text style={{ color: theme.palette.gray[700] }}>
                {locationLanguages.map((language) => language).join(', ')}
              </Text>
            </Form.Column>
          </Form.Row>
        )}
        {locationServices.length > 0 && (
          <Form.Row>
            <Form.Column>
              <View
                style={{
                  borderBottomColor: theme.palette.gray[300],
                  borderBottomWidth: 1,
                  marginBottom: theme.getSpacing(1),
                  marginTop: theme.getSpacing(2),
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    paddingTop: theme.getSpacing(2),
                    paddingBottom: theme.getSpacing(2),
                  }}
                >
                  {getText('services-offered')}
                </Text>
              </View>
              <Text style={{ color: theme.palette.gray[700] }}>
                {locationServices.map((service) => service).join(', ')}
              </Text>
            </Form.Column>
          </Form.Row>
        )}
      </ScrollView>
    </ScreenSafeContainer>
  );
};

interface RegisterStoreDetailsProps
  extends NativeStackScreenProps<
    AuthStackParamList,
    'patient-pharmacy-stores'
  > {}

const useStyles = makeStyles((theme) => ({
  listStyle: {
    margin: 0,
    marginRight: -12,
  },
  titleStyle: {
    marginStart: -theme.getSpacing(1),
    marginEnd: -theme.getSpacing(1),
    paddingLeft: 0,
    color: theme.palette.gray[700],
  },
  hoursStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.getSpacing(1),
  },
}));
