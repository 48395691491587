import { useState, useEffect } from 'react';
import { useLocation } from '../../../hooks/useLocation';
import { haversineDistance } from '../../../utils';
import { PermissionStatus } from 'expo-location';
import { Distance, LocationInfo } from '../../../types';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';

export type StoresWithLocationInfo = {
  stores: Distance<PharmacyLocationDto>[];
  locationInfo: LocationInfo;
};

export const useStoresWithDistanceInfo = (
  stores: PharmacyLocationDto[],
): StoresWithLocationInfo => {
  const [storesWithDistanceInfo, setStoresWithDistanceInfo] =
    useState<StoresWithLocationInfo>({
      stores: stores ?? [],
      locationInfo: { status: PermissionStatus.UNDETERMINED, granted: false },
    });

  const {
    latitude,
    longitude,
    status, // Default is 'UNDETERMINED'
    granted,
  } = useLocation();

  useEffect(() => {
    (async () => {
      // In case of denied location permission
      if (status === PermissionStatus.DENIED) {
        setStoresWithDistanceInfo({
          stores: stores,
          locationInfo: { latitude, longitude, status, granted },
        });
      }

      // In case of granted location permissions
      if (status === PermissionStatus.GRANTED) {
        if (!stores || stores.length === 0) {
          return;
        }
        if (!latitude || !longitude) return;

        const storesDistanceInfo = stores.map(
          (x) =>
            ({
              ...x,
              distance: haversineDistance(
                { latitude, longitude },
                x,
                'mi', // TODO: info to be gathered from users preferences | system
              ),
              distanceUnit: 'mi', // TODO: info to be gathered from users preferences | system
            }) as Distance<PharmacyLocationDto>,
        );
        setStoresWithDistanceInfo({
          stores: storesDistanceInfo,
          locationInfo: { latitude, longitude, status, granted },
        });
      }
    })();
  }, [status, stores, latitude, longitude]);

  return storesWithDistanceInfo;
};
