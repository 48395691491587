import {
  AppointmentTypeDto,
  BookingDto,
  FormDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { TouchableOpacity, View, Linking, Platform } from 'react-native';
import {
  useNavigation,
  useFocusEffect,
  useIsFocused,
} from '@react-navigation/native';
import { Card } from 'assets/components/card/Card';
import { IconButton } from 'assets/components/icon-button';
import { Text } from 'assets/components/text';
import {
  CalendarIcon,
  ChevronRightIcon,
  LocationIcon,
  UserIcon,
} from 'assets/icons';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { formatDateTimeWithTimezone } from '../../common/datetime-utils';
import {
  DEFAULT_UTC_OFFSET,
  formatPhoneNumber,
} from './book-appointment/book-appointment-utils';
import { AppointmentDetailsScreenRouteProp } from '../../navigation/RootNavigation';
import { useUserState } from '../../store/user-store';
import patientService from '../../api/patient-service';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import moment from 'moment';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { getApps } from 'react-native-map-link';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';
import { logError } from 'assets/logging/logger';
import { useAppStateStore } from '../../store/app-store';
import shallow from 'zustand/shallow';
import { AppointmentDetailsBadge } from '../appointment/AppointmentDetailsBadge';
import AppointmentService from '../../api/appointment-service';
import { useAppointmentsState } from './appointments-store';
import { FormStatus } from '@digitalpharmacist/forms-service-client-axios';
import { AppointmentChecklistBadge } from '../appointment/AppointmentChecklistBadge';

export const Appointment: FunctionComponent<AppointmentProps> = ({
  appointment,
  type,
}) => {
  const { user } = useUserState();
  const navigation = useNavigation<AppointmentDetailsScreenRouteProp>();
  const theme = useTheme();
  const styles = useStyles();
  const isFocused = useIsFocused();
  const [patient, setPatient] = useState<PatientRecordDto>();
  const [appointmentFormsCompleted, setAppointmentFormsCompleted] =
    useState<boolean>();
  const [loadingPatient, setLoadingPatient] = useState(false);
  const { appointmentLocation } = useAppStateStore(
    (state) => ({
      ...state,
      appointmentLocation: state.stores.find(
        (store) => store.id === appointment.location_id,
      ),
    }),
    shallow,
  );

  useFocusEffect(
    useCallback(() => {
      const getAppointmentType = async () => {
        try {
          const response = await AppointmentService.findAppointmentTypeById(
            appointment.pharmacy_id,
            appointment.location_id,
            appointment.appointment_type_id,
          );

          if (response.forms.length === 0) {
            setAppointmentFormsCompleted(true);
            return;
          }

          const validForms = response.forms
            .filter((form) => form.form_status === FormStatus.Enabled)
            .map((form) => form.form_id);

          setAppointmentFormsCompleted(
            appointment.submissions.length > 0 &&
              appointment.submissions.every((sub) =>
                validForms.includes(sub.form_id),
              ),
          );
        } catch (error) {
          useAppointmentsState.setState({
            error: {
              message: getText('error-loading-appointments'),
            },
            status: 'error',
          });
        }
      };

      void getAppointmentType();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      if (!user?.preferredPharmacyLocationId || !isFocused || patient) return;

      setLoadingPatient(true);

      patientService
        .findPatientRecord(appointment.patient_record_id)
        .then((patientRecord) => setPatient(patientRecord))
        .catch((error) => logError(error))
        .finally(() => setLoadingPatient(false));
    }, [isFocused, user?.preferredPharmacyLocationId]),
  );

  const { showActionSheetWithOptions } = useActionSheet();

  const handleOpenActionSheet = (
    latitude: number | undefined,
    longitude: number | undefined,
  ) => {
    if (latitude === undefined || longitude === undefined) {
      return;
    }

    void (async () => {
      const availableApps = await getApps({
        latitude: latitude,
        longitude: longitude,
        googleForceLatLon: false,
        alwaysIncludeGoogle: true,
      });

      showActionSheetWithOptions(
        {
          options: [...availableApps.map((app) => app.name), getText('cancel')],
          cancelButtonIndex: availableApps.length,
        },
        async function (buttonIndex) {
          if (
            buttonIndex === undefined ||
            buttonIndex === availableApps.length
          ) {
            // the element at index availableApps.length is the Cancel button
            return;
          }

          await availableApps[buttonIndex]?.open();
        },
      );
    })();
  };

  const isChecklistComplete = !!(
    appointmentFormsCompleted &&
    appointment.bring_insurance_card &&
    appointment.review_related_health_info
  );

  const handleOnPhonePress = async () => {
    if (appointmentLocation?.phone)
      await Linking.openURL(`tel:${appointmentLocation.phone}`);
    return;
  };

  return (
    <View style={styles.container}>
      <Card
        title=""
        titleComponent={<></>}
        touchable={false}
        content={
          <View style={styles.flexRow}>
            <View
              style={{
                marginTop: getSpacing(2),
              }}
            >
              <CalendarIcon color={theme.palette.gray[500]} size={35} />
            </View>

            <View style={styles.flexColumn}>
              <View style={styles.contentContainer}>
                <View style={styles.textContainer}>
                  <Text style={styles.date}>
                    {formatDateTimeWithTimezone(
                      appointment.startTime,
                      DEFAULT_UTC_OFFSET,
                      'dddd M/D @ h:mm A',
                    )}
                  </Text>
                  <Text style={styles.title}>{appointment.title}</Text>
                  {loadingPatient && <LoadingIndicator size={24} />}
                  {patient && type === 'upcoming' && (
                    <View style={styles.patientContainer}>
                      <UserIcon color={theme.palette.gray[500]} size={18} />
                      <Text style={styles.patientName}>
                        {patient.first_name} {patient.last_name}
                      </Text>
                      <Text style={styles.patientDate}>
                        {moment(patient.date_of_birth, 'YYYY-MM-DD').format(
                          'MM/DD/YYYY',
                        )}
                      </Text>
                    </View>
                  )}
                  {appointmentFormsCompleted === undefined && (
                    <LoadingIndicator size={20} />
                  )}
                  {appointmentFormsCompleted !== undefined &&
                  type === 'upcoming' ? (
                    <AppointmentChecklistBadge complete={isChecklistComplete} />
                  ) : null}
                  {appointmentLocation && (
                    <>
                      <Text style={styles.address}>
                        {appointmentLocation.name}
                      </Text>
                      {appointmentLocation.address?.address1 &&
                      type === 'upcoming' ? (
                        <>
                          <Text style={styles.address}>
                            {appointmentLocation.address.address1}
                            {appointmentLocation.address.address2 &&
                              `, ${appointmentLocation.address.address2}`}
                          </Text>
                          <Text style={styles.address}>
                            {appointmentLocation.address.city},{' '}
                            {appointmentLocation.address.state}{' '}
                            {appointmentLocation.address.postal_code}
                          </Text>
                        </>
                      ) : null}
                    </>
                  )}
                </View>
                <View style={styles.iconContainer}>
                  <IconButton
                    icon={ChevronRightIcon}
                    logger={{ id: 'appointment-details-button' }}
                    onPress={() =>
                      navigation.navigate('appointment', {
                        appointment_id: appointment.id,
                        location_id: appointment.location_id,
                      })
                    }
                    color={theme.palette.gray[500]}
                  />
                </View>
              </View>

              {!!(
                appointmentLocation?.phone ||
                (appointmentLocation?.latitude &&
                  appointmentLocation.longitude &&
                  type === 'upcoming')
              ) && (
                <View style={styles.phoneAndDirectionsContainer}>
                  {appointmentLocation.phone && type === 'upcoming' ? (
                    <TouchableOpacity onPress={handleOnPhonePress}>
                      <Text style={styles.phoneNumber}>
                        {formatPhoneNumber(appointmentLocation.phone)}
                      </Text>
                    </TouchableOpacity>
                  ) : null}

                  {appointmentLocation.latitude &&
                  appointmentLocation.longitude &&
                  type === 'upcoming' &&
                  appointment.status !== 'CANCELLED' ? (
                    <View>
                      {Platform.OS == 'web' ? (
                        <TouchableOpacity
                          style={styles.getDirectionsContainer}
                          onPress={() =>
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${appointmentLocation.latitude},${appointmentLocation.longitude}`,
                              '_blank',
                            )
                          }
                        >
                          <Icon
                            icon={LocationIcon}
                            size={16}
                            color={theme.palette.primary['600']}
                          />
                          <Text style={styles.getDirectionsText}>
                            {getText('get-directions')}
                          </Text>
                        </TouchableOpacity>
                      ) : (
                        <TouchableOpacity
                          style={styles.getDirectionsContainer}
                          onPress={() => {
                            handleOpenActionSheet(
                              appointmentLocation.latitude,
                              appointmentLocation.longitude,
                            );
                          }}
                        >
                          <Icon
                            icon={LocationIcon}
                            size={16}
                            color={theme.palette.primary['600']}
                          />
                          <Text style={styles.getDirectionsText}>
                            {getText('get-directions')}
                          </Text>
                        </TouchableOpacity>
                      )}
                    </View>
                  ) : null}
                </View>
              )}
              {appointment.status === 'CANCELLED' && (
                <View style={styles.badgeContainer}>
                  <AppointmentDetailsBadge status={'canceled'} />
                </View>
              )}
            </View>
          </View>
        }
      />
    </View>
  );
};

export interface AppointmentProps {
  appointment: BookingDto;
  type: 'upcoming' | 'past';
}

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    marginBottom: theme.getSpacing(2),
  },
  contentContainer: {
    flexDirection: 'row',
    paddingTop: theme.getSpacing(2),
  },
  textContainer: {
    marginRight: 'auto',
    marginLeft: theme.getSpacing(2),
  },
  date: {
    ...theme.fonts.medium,
    fontSize: 16,
    lineHeight: 18,
    marginBottom: theme.getSpacing(1),
    color: theme.palette.gray[900],
  },
  title: {
    ...theme.fonts.medium,
    fontSize: 13,
    lineHeight: 15,
    marginBottom: theme.getSpacing(1),
    color: theme.palette.gray[900],
  },
  patientContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(1),
  },
  patientName: {
    marginHorizontal: theme.getSpacing(1),
    fontSize: 13,
  },
  patientDate: {
    color: theme.palette.gray[400],
    fontSize: 13,
  },
  address: {
    fontSize: 13,
    lineHeight: 15,
    color: theme.palette.gray[500],
  },
  iconContainer: {
    marginRight: -12, // to align it better inside the card
    marginTop: -12, // to align it better inside the card
  },
  phoneAndDirectionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: theme.getSpacing(2),
  },
  phoneNumber: {
    color: theme.palette.primary[600],
    marginTop: theme.getSpacing(1),
  },
  getDirectionsContainer: {
    marginTop: theme.getSpacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  },
  getDirectionsText: {
    marginRight: 'auto',
    marginLeft: theme.getSpacing(0.5),
    color: theme.palette.primary[600],
  },
  badgeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
}));
