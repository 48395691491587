import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { USER_API_BASE_URL } from '../common/constants';
import {
  GeneralApi as UsersServiceClient,
  UserDetails,
  UserLogin,
  UserLoginResponse,
  VerifyPhoneNumberRequest,
  ResetPasswordResponse,
  TermsAndConditionResponse,
  UpdateUserRequest,
  UserRegisterDto,
  PatientUserDto,
  CheckUserDto,
  UserPatientPasswordSetPost200Response,
  PharmacyUserDto,
} from '@digitalpharmacist/users-service-client-axios';

export interface IUsersService {
  logIn(values: UserLogin): Promise<UserLoginResponse | null>;
  getUser(userId: string): Promise<PatientUserDto>;
  verifyPhoneNumber(
    token: string,
    verifyPhoneNumberRequest: VerifyPhoneNumberRequest,
  ): Promise<PatientUserDto>;
  resetPassword(values: object): Promise<ResetPasswordResponse>;
  confirmPassword(values: object): Promise<void>;
  registerPatient(values: UserLogin): Promise<PatientUserDto>;
  updatePatient(
    userId: string,
    updateUserRequest: UpdateUserRequest,
  ): Promise<PatientUserDto>;
  userPatientIdPrivacyAndPolicyPatch(
    id: string,
  ): Promise<TermsAndConditionResponse>;
  userPatientIdTermsAndConditionPatch(
    id: string,
  ): Promise<TermsAndConditionResponse>;
  userPatientAccountVerificationPost(body: object): Promise<UserLoginResponse>;
  userPatientResendConfirmationCodePost(
    userDetails?: UserDetails,
  ): Promise<void>;
  userPatientResendPhoneNumberConfirmationCodePost(): Promise<void>;
  checkUser(email: string, pharmacy_id: string): Promise<CheckUserDto>;
  passwordSet(values: object): Promise<UserPatientPasswordSetPost200Response>;
}

export class UsersService extends BaseApiService implements IUsersService {
  private userServiceClient: UsersServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config as any, enableAuth, baseConfig);
    this.userServiceClient = new UsersServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async logIn(userLogin: UserLogin): Promise<UserLoginResponse> {
    return (
      await this.userServiceClient.userPatientLoginPost(userLogin, {
        disableAuth: true,
      })
    ).data;
  }

  async verifyPhoneNumber(
    token: string,
    verifyPhoneNumberRequest: VerifyPhoneNumberRequest,
  ): Promise<PatientUserDto> {
    return (
      await this.userServiceClient.userPatientPhoneNumberVerificationPost(
        `Bearer ${token}`,
        verifyPhoneNumberRequest,
        {
          disableAuth: true,
        },
      )
    ).data;
  }

  async getUser(userId: string): Promise<PatientUserDto> {
    return (await this.userServiceClient.userPatientUserIdGet(userId)).data;
  }

  async getPharmacistUser(userId: string): Promise<PharmacyUserDto> {
    return (await this.userServiceClient.userPharmacistIdGet(userId)).data;
  }

  async resetPassword(values: UserDetails): Promise<ResetPasswordResponse> {
    return (await this.userServiceClient.userPatientResetPasswordPost(values))
      .data;
  }

  async confirmPassword(values: object): Promise<void> {
    await this.userServiceClient.userPatientConfirmPasswordPost(values);
  }

  async registerPatient(values: UserRegisterDto): Promise<PatientUserDto> {
    return (await this.userServiceClient.userPatientRegisterPost(values)).data;
  }

  async updatePatient(
    userId: string,
    updateUserRequest: UpdateUserRequest,
  ): Promise<PatientUserDto> {
    return (
      await this.userServiceClient.userPatientUserIdPatch(
        userId,
        updateUserRequest,
      )
    ).data;
  }

  async userPatientIdPrivacyAndPolicyPatch(
    id: string,
  ): Promise<TermsAndConditionResponse> {
    return (await this.userServiceClient.userPatientIdPrivacyAndPolicyPatch(id))
      .data;
  }

  async userPatientIdTermsAndConditionPatch(
    id: string,
  ): Promise<TermsAndConditionResponse> {
    return (
      await this.userServiceClient.userPatientIdTermsAndConditionPatch(id)
    ).data;
  }

  async userPatientAccountVerificationPost(
    body: object,
  ): Promise<UserLoginResponse> {
    return (
      await this.userServiceClient.userPatientAccountVerificationPost(body)
    ).data;
  }

  async userPatientResendConfirmationCodePost(
    userDetails?: UserDetails,
  ): Promise<void> {
    await this.userServiceClient.userPatientResendConfirmationCodePost(
      userDetails,
    );
  }

  async userPatientResendPhoneNumberConfirmationCodePost(): Promise<void> {
    await this.userServiceClient.userPatientResendPhoneNumberConfirmationCodePost();
  }

  async checkUser(email: string, pharmacy_id: string): Promise<CheckUserDto> {
    return (
      await this.userServiceClient.userPatientCheckUserPharmacyIdEmailGet(
        pharmacy_id,
        email,
      )
    ).data;
  }

  async passwordSet(
    values: object,
  ): Promise<UserPatientPasswordSetPost200Response> {
    return (await this.userServiceClient.userPatientPasswordSetPost(values))
      .data;
  }
}

export default new UsersService(USER_API_BASE_URL, undefined, true);
