import {
  PatientRecordDto,
  RecordUnderCareDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useTheme } from 'react-native-paper';
import { TouchableOpacity, View, Image } from 'react-native';
import { Text } from 'assets/components/text';
import { PatientUnderCareForm } from '../../patient-actions';
import { UseFormReturn, useForm } from 'react-hook-form';
import { PatientUnderCareEditDetailsEnum } from '../../patient-store';
import {
  DateTimeFormat,
  formatDateAsString,
} from '../../../../../common/form-utils';
import { makeStyles } from 'assets/theme';

export const PersonalInformationComponent: FunctionComponent<
  PropsWithChildren<{
    patientRecord: PatientRecordDto;
    record?: RecordUnderCareDto;
    onEditPress: (
      editDetail: PatientUnderCareEditDetailsEnum,
      methods: UseFormReturn<PatientUnderCareForm>,
    ) => void;
    form?: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({ onEditPress, patientRecord, record, form }) => {
  const theme = useTheme();
  const styles = useStyles();
  const methods = useForm<PatientUnderCareForm>({});
  const address = patientRecord.address
    ? `${patientRecord.address.address1} ${patientRecord.address.address2} ${patientRecord.address.city} ${patientRecord.address.state} ${patientRecord.address.postal_code} ${patientRecord.address.country}`.trim()
    : getText('none');

  return (
    <View>
      <View style={styles.requestTextContainer}>
        <Text style={styles.title}>
          {getText('personal-info').toUpperCase()}
        </Text>
        <TouchableOpacity
          style={styles.textContainer}
          onPress={() =>
            onEditPress(PatientUnderCareEditDetailsEnum.PersonalInfo, methods)
          }
        >
          <Text style={[styles.pressableTextRevoke, styles.leftSpacing]}>
            {getText('edit')}
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ marginBottom: theme.getSpacing(3) }}>
        <Text style={styles.subtitle}>{getText('first-name')}</Text>
        <Text style={styles.boldSubtitle}>{patientRecord.first_name}</Text>
      </View>
      <View style={{ marginBottom: theme.getSpacing(3) }}>
        <Text style={styles.subtitle}>{getText('last-name')}</Text>
        <Text style={styles.boldSubtitle}>{patientRecord.last_name}</Text>
      </View>
      <View style={{ marginBottom: theme.getSpacing(3) }}>
        <Text style={styles.subtitle}>{getText('date-of-birth')}</Text>
        <Text style={styles.boldSubtitle}>
          {patientRecord.date_of_birth
            ? formatDateAsString(
                patientRecord.date_of_birth,
                DateTimeFormat.USDateFormat,
              )
            : ''}
        </Text>
      </View>
      {record && (
        <View style={{ marginBottom: theme.getSpacing(3) }}>
          <Text style={styles.subtitle}>
            {getText('relation-to-primary-user')}
          </Text>

          <Text style={styles.boldSubtitle}>{record.relationship}</Text>
        </View>
      )}
      <View style={{ marginBottom: theme.getSpacing(3) }}>
        <Text style={styles.subtitle}>{getText('gender')}</Text>
        <Text style={styles.boldSubtitle}>
          {patientRecord.gender ? patientRecord.gender : getText('none')}
        </Text>
      </View>
      <View style={{ marginBottom: theme.getSpacing(3) }}>
        <Text style={styles.subtitle}>{getText('address')}</Text>
        <Text style={styles.boldSubtitle}>{address} </Text>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.getSpacing(1),
    fontSize: 12,
    lineHeight: 16,
    fontWeight: '800',
  },
  subtitle: {
    fontWeight: '400',
    color: theme.palette.gray[600],
    fontSize: 14,
    lineHeight: 20,
  },
  boldSubtitle: {
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 28,
    color: theme.palette.gray[900],
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(3),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(1),
  },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
}));
