import { Form, ScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'assets/components/text/Text';
import { TextField } from 'assets/components/text-field';
import { Barcode } from '../../../camera/barcode/Barcode';
import { useForm } from 'react-hook-form';
import { Button } from 'assets/components/button';
import { Divider } from 'react-native-paper';
import { IconButton } from 'assets/components/icon-button';
import { TrashIcon } from 'assets/icons';
import { FindPrescriptionFlowTestIDs } from '../FindPrescriptionFlowTestIDs';
import { getText } from 'assets/localization/localization';
import refillService from '../../refill/refill-service';
import { useAddPrescriptionStore } from '../add-prescription-store';
import { useUserState } from '../../../store/user-store';
import {
  CreatePrescriptionDto,
  IngressMethod,
  IntegrationType,
  RefillStatus,
} from '@digitalpharmacist/prescription-service-client-axios';
import moment from 'moment/moment';
import { useAppStateStore } from '../../../store/app-store';
import { StackHeaderProps } from '@react-navigation/stack';

export const ManuallyAddPrescription: FunctionComponent<
  PropsWithChildren<ManuallyAddPrescriptionProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();

  const { user } = useUserState();
  const { pharmacyId } = useAppStateStore();
  const { locationPatientRecordId } = useAddPrescriptionStore();

  const methods = useForm<PrescriptionType>({
    defaultValues: {
      rxNumber: '',
      drugName: '',
    },
  });

  const [isScannerVisible, setIsScannerVisible] = useState(false);
  const [scannedValue, setScannedValue] = useState('');
  const [drugsArray, setDrugsArray] = useState<PrescriptionType[]>([]);

  const handleScan = (value: string) => {
    setScannedValue(value);
    setIsScannerVisible(false);
  };

  const handleOpenScanner = () => {
    setIsScannerVisible(true);
  };

  const handleScannerClose = () => {
    setIsScannerVisible(false);
  };

  const handlePrescription = () => {
    let newPrescription = {
      drugName: methods.getValues().drugName,
      rxNumber: methods.getValues().rxNumber,
    };
    setDrugsArray((current) => [...current, newPrescription]);
  };

  const handleDelete = () => {};

  const handleOnPressDone = async () => {
    if (!user?.preferredPharmacyLocationId || !locationPatientRecordId) return;

    for (const newDrug of drugsArray) {
      await refillService.createPatientPrescriptions(
        user.preferredPharmacyLocationId,
        locationPatientRecordId,
        {
          drug_name: newDrug.drugName,
          rx_number: newDrug.rxNumber,
          pharmacy_id: pharmacyId,
          location_id: user.preferredPharmacyLocationId,
          patient_id: locationPatientRecordId,
          integration_type: IntegrationType.Manual,
          ingress_method: IngressMethod.Web,
          refill_status: RefillStatus.Success, //TODO: change the status if needed
        } as CreatePrescriptionDto,
      );
    }
    navigation.navigate('app', { screen: 'medications' });
  };

  return (
    <ScreenContainer>
      <Text style={styles.textTitle}>{getText('add-your-prescriptions')}</Text>
      <Divider />
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <TextField
              type="barcode"
              label={getText('rx-number')}
              name="rxNumber"
              handleOpenScanner={handleOpenScanner}
              onSubmit={methods.handleSubmit(handlePrescription)}
              rules={{
                required: getText('rx-number-is-required'),
              }}
              value={scannedValue}
              testID={FindPrescriptionFlowTestIDs.rxNumberTextField}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              type="text"
              label={getText('drug-name')}
              name="drugName"
              rules={{
                required: getText('rx-number-is-required'),
              }}
              onSubmit={methods.handleSubmit(handlePrescription)}
              testID={FindPrescriptionFlowTestIDs.drugNameTextField}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column style={styles.addPrescriptionButton}>
            <Form.Actions>
              <Button
                hierarchy="secondary-gray"
                onPress={methods.handleSubmit(handlePrescription)}
                logger={{ id: 'add-prescription' }}
                testID={FindPrescriptionFlowTestIDs.addPrescriptionButton}
              >
                {getText('add')}
              </Button>
            </Form.Actions>
          </Form.Column>
        </Form.Row>
      </Form>
      {drugsArray.length > 0 && (
        <>
          <Text style={styles.textTitle}>{getText('your-prescriptions')}</Text>
          <Divider />
        </>
      )}
      {drugsArray.map((item, index) => (
        <View key={index} style={styles.container}>
          <View style={styles.row}>
            <Text style={styles.textTitleCard}>{item.drugName}</Text>
            <IconButton
              icon={TrashIcon}
              color={theme.palette.gray[500]}
              onPress={() => handleDelete()}
              style={{
                margin: 0,
                padding: 0,
              }}
              logger={{ id: 'deleteIcon' }}
              size={15}
              testID={FindPrescriptionFlowTestIDs.trashIconButton}
            />
          </View>
          <Text style={styles.textSubtitleCard}>Rx: {item.rxNumber}</Text>
          <Text style={styles.textSubtitleCard}>
            {user?.firstName} {user?.lastName}{' '}
            {moment(user?.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY')}
          </Text>
          <Text style={styles.textSubtitleCardSmall}>
            {getText('entered-prescription-manually')}
          </Text>
          <Divider />
        </View>
      ))}
      <View style={{ bottom: 0, left: 0, right: 0, position: 'absolute' }}>
        {drugsArray.length === 0 && (
          <Button
            hierarchy="tertiary"
            testID={FindPrescriptionFlowTestIDs.doThisLaterButton}
            logger={{ id: 'add-prescription' }}
          >
            {getText('do-this-later')}
          </Button>
        )}
        <Button
          hierarchy="primary"
          onPress={handleOnPressDone}
          testID={FindPrescriptionFlowTestIDs.doneButton}
          logger={{ id: 'add-prescription' }}
        >
          {getText('done')}
        </Button>
      </View>
      <View style={{ margin: theme.getSpacing(1) }}>
        <Barcode
          onScan={handleScan}
          isVisible={isScannerVisible}
          onClose={handleScannerClose}
        />
      </View>
    </ScreenContainer>
  );
};

export interface ManuallyAddPrescriptionProps extends StackHeaderProps {}

interface PrescriptionType {
  drugName: string;
  rxNumber: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  container: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(2),
  },
  textTitleCard: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 20,
  },
  textSubtitleCard: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
  },
  textSubtitleCardSmall: {
    ...theme.fonts.medium,
    color: theme.palette.gray[500],
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 20,
    marginBottom: theme.getSpacing(2),
  },
  addPrescriptionButton: {
    width: '50%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginBottom: theme.getSpacing(2),
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
    justifyContent: 'space-between',
  },
}));
